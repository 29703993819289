import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import Box from "@mui/material/Box";
import { GridToolbarQuickFilter, GridLinkOperator } from "@mui/x-data-grid-pro";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useDemoData } from "@mui/x-data-grid-generator";
import TextInputField from "../../Components/InputFields/TextInputField/TextInputField";
import { TextField } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { GridApi } from "@mui/x-data-grid-pro";
import FormControl from "@mui/material/FormControl";
import { NumericFormat } from "react-number-format";
import { formatInvestmentAmount } from "../../utilities/functions/functions.js";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { select } from "underscore";
import MuiButton from "../../Components/Buttons/MuiButton/MuiButton";
import ErrorComponent from "./ErrorComponent/ErrorComponent";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      // isNumericString
    />
  );
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 2,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
    </Box>
  );
}

const VISIBLE_FIELDS = [
  "full_name",
  "client_email",
  "account_type",
  "investment_amount",
];

function MultiSelectTable({
  fundInvestmentInformation,
  loading,
  rows,
  investments,
  setInvestments,
  setShowConfirmInvestments,
}) {
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 100000,
    editable: true,
  });

  const [proposedInvestments, setProposedInvestments] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [errorInfo, setErrorInfo] = useState("");

  const apiRef = useGridApiRef();

  const updateRowInvestmentAmountState = (key, value) => {
    const newArray = rows.map((item, i) => {
      if (item.id == key) {
        return { ...item, investment_amount: value };
      } else {
        return item;
      }
    });
  };

  const setInvestmentAmounts = (key, value) => {
    setProposedInvestments((prevState) => ({
      ...prevState,
      [key]: { investmentAmount: value },
    }));
    // updateRowInvestmentAmountState(key, value);
  };

  const [investmentAmount, setInvestmentAmount] = useState("");
  const [investmentAmountError, setInvestmentAmountError] = useState("");

  const isRowSelected = (rowId) => {
    return selectedRows.includes(rowId);
  };

  const handleUpdateRow = (rowId, value) => {
    apiRef.current.updateRows([
      { id: rowId, proposed_investment_amount: value },
    ]);
  };

  useEffect(() => {
    if (investments.length !== 0) {
      let selectedRows = [];
      let updateRows = [];

      investments.forEach((item) => {
        selectedRows.push(item.id);
        handleUpdateRow(item.id, item.proposed_investment_amount);
        // updateRows.push({
        //   id: item.id,
        //   proposed_investment_amount: item.proposed_investment_amount,
        // });
      });

      //   console.log("Update rows are: " + JSON.stringify(updateRows));

      if (
        apiRef !== null &&
        apiRef !== undefined &&
        apiRef.current !== null &&
        apiRef.current !== undefined
      ) {
        // apiRef.current.updateRows(updateRows);
        apiRef.current.selectRows(selectedRows, true);
      }
    }
  }, [investments]);

  const handleUpdateRowDisabledInput = () => {
    const allRows = apiRef.current.getAllRowIds();

    for (let i = 0; i < allRows.length; i++) {
      if (selectedRows.includes(allRows[i])) {
        apiRef.current.updateRows([{ id: allRows[i], disabled_input: false }]);
      } else {
        apiRef.current.updateRows([{ id: allRows[i], disabled_input: true }]);
      }
    }
  };

  const MoneyTextField = (rowId, investmentAmount) => {
    return (
      <CurrencyTextField
        label="Amount"
        variant="standard"
        size="small"
        value={investmentAmount}
        currencySymbol="$"
        //minimumValue="0"
        outputFormat="string"
        decimalCharacter="."
        digitGroupSeparator=","
        onChange={(event) => {
          handleUpdateRow(rowId, event.target.value);
        }}
      />
    );
  };

  let accountTypeMapping = {
    IND: "Individual",
    TRUST: "Irrevocable Trust",
    LT: "Living Trust",
    SM_LLC: "Single Member LLC",
    LLC: "Limited Liability Company",
    C_Corp: "C Corporation",
    S_Corp: "S Corporation",
    LP: "Limited Partnership",
    GP: "General Partnership",
    TRAD_IRA: "Traditional IRA",
    ROTH_IRA: "Roth IRA",
    SEP_IRA: "SEP IRA",
    SOLO_401K: "Solo 401K",
    SELF_DIRECTED_401K: "Self Directed 401K",
  };

  const columns = [
    { field: "id", hide: true, sortable: false },
    {
      field: "full_name",
      headerName: "Client Name",
      sortable: false,
      flex: 1,
      resizable: false,
    },
    {
      field: "client_email",
      headerName: "Client Email",
      sortable: false,
      flex: 2,
      resizable: false,
    },
    {
      field: "account_type",
      headerName: "Account Type",
      sortable: false,
      flex: 1,
      resizable: false,
      renderCell: (params) => {
        return <div>{accountTypeMapping[params.row.account_type]}</div>;
      },
    },
    {
      field: "investment_amount",
      headerName: "Investment Amount",
      renderCell: (params) => {
        return (
          <div className="row g-0 w-100">
            <div className="p-2 mt-2 mb-2">
              {/* <TextInputField
                value={investmentAmount}
                setValue={setInvestmentAmount}
                valueError={investmentAmountError}
                setValueError={setInvestmentAmountError}
                label={"Investment Amount"}
                required={true}
                fullWidth={true}
                size={"small"}
                id={"investment-amount-input"}
              /> */}
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">
                  Amount
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  type="number"
                  label="Amount"
                  size="small"
                  value={params.row.proposed_investment_amount}
                  disabled={!apiRef.current.isRowSelected(params.row.id)}
                  onChange={(event) => {
                    // setInvestmentAmounts(
                    //   params.row.id,
                    //   formatInvestmentAmount(event.target.value)
                    // );
                    handleUpdateRow(params.row.id, event.target.value);
                  }}
                />
              </FormControl>
              {/* <MoneyTextField
                rowId={params.row.id}
                investmentAmount={params.row.proposed_investment_amount}
              /> */}
              {/* <CurrencyTextField
                label="Amount"
                variant="standard"
                size="small"
                value={proposedInvestments[params.row.id.investmentAmount]}
                currencySymbol="$"
                //minimumValue="0"
                outputFormat="string"
                decimalCharacter="."
                digitGroupSeparator=","
                onChange={(event) => {
                  setInvestmentAmounts(params.row.id, event.target.value);
                  //   handleUpdateRow(params.row.id, event.target.value);
                }}
              /> */}
              {/* <CurrencyFormat
                customInput={TextField}
                thousandSeparator
                prefix="$"
                decimalScale={0}
                placeholder="Amount"
                label="Amount"
                
                
              /> */}
              {/* <TextField
                variant="outlined"
                fullWidth={true}
                defaultValue={"Investment Amount"}
                size="small"
              /> 
              InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
              */}
            </div>
          </div>
        );
      },
      flex: 1,
      resizable: false,
      rendererIsEditable: true,
    },
  ];

  const showColumns = React.useMemo(
    () => columns.filter((column) => VISIBLE_FIELDS.includes(column.field)),
    [data.columns]
  );

  //   useEffect(() => {
  //     if (rows !== null && rows !== undefined) {
  //       console.log("Called.");
  //       let currentProposedInvestments = proposedInvestments;
  //       for (let i = 0; i < rows.length; i++) {
  //         if (!currentProposedInvestments.hasOwnProperty(rows[i].id)) {
  //           currentProposedInvestments[rows[i].id] = {
  //             investmentAmount: "12",
  //           };
  //         }
  //       }

  //       setProposedInvestments(currentProposedInvestments);
  //     }
  //   }, []);

  const validateInvestments = () => {
    var errors = false;

    var errorDetails = {};

    var minimumInvestment = 100000;
    var maximumInvestment = 200000;
    var remainingAllocation = 10000000;
    var maxFundSize = 505000000;
    var committedAllocation = 0;

    if (
      fundInvestmentInformation !== null &&
      fundInvestmentInformation !== undefined
    ) {
      minimumInvestment = fundInvestmentInformation.minimum_allocation_amount;
      maximumInvestment = fundInvestmentInformation.maximum_allocation_amount;
      committedAllocation =
        fundInvestmentInformation.committed_allocation_amount;
      maxFundSize = fundInvestmentInformation.max_fund_size;
    }

    var runSum = committedAllocation;

    var selectedRowsHere = apiRef.current.getSelectedRows();
    var allRows = apiRef.current.getAllRowIds();
    let allInvestments = [];

    selectedRowsHere.forEach((value, key) => {
      allInvestments.push(value);
    });

    for (let i = 0; i < allInvestments.length; i++) {
      if (allInvestments[i].proposed_investment_amount === "") {
        errors = true;
        errorDetails[allInvestments[i].id] = {};
        errorDetails[allInvestments[i].id].fullName =
          allInvestments[i].full_name;
        errorDetails[allInvestments[i].id].error =
          "Investment amount is not specified.";
      } else if (
        parseInt(allInvestments[i].proposed_investment_amount) <
        minimumInvestment
      ) {
        errors = true;
        errorDetails[allInvestments[i].id] = {};
        errorDetails[allInvestments[i].id].fullName =
          allInvestments[i].full_name;
        errorDetails[allInvestments[i].id].error =
          "Investment amount must be greater than the minimum investment amount.";
      } else if (
        parseInt(allInvestments[i].proposed_investment_amount) >
          maximumInvestment &&
        maximumInvestment !== 0
      ) {
        errors = true;
        errorDetails[allInvestments[i].id] = {};
        errorDetails[allInvestments[i].id].fullName =
          allInvestments[i].full_name;
        errorDetails[allInvestments[i].id].error =
          "Investment amount must be less than the maximum investment amount.";
      }

      if (allInvestments[i].proposed_investment_amount !== "") {
        runSum += parseInt(allInvestments[i].proposed_investment_amount);
      }
    }

    if (runSum > maxFundSize) {
      errors = true;
      errorDetails["overall"] = {};
      errorDetails["overall"].error =
        "The sum of all proposed investments must be less than the remaining allocation amount for this fund.";
    }

    if (allInvestments.length === 0) {
      errors = true;
      errorDetails["overall"] = {};
      errorDetails["overall"].error =
        "You must specify at least one investment.";
    }

    if (!errors) {
      setInvestments(allInvestments);
      setShowConfirmInvestments(true);
      setErrorInfo("");
    } else {
      let errorArray = [];

      //   errorDetails.forEach((value, key) => {
      //     errorArray.push(value);
      //   });

      for (const variable in errorDetails) {
        errorArray.push(errorDetails[variable]);
      }
      //   errorDetails.forEach((value, key) => {
      //     errorArray.push(value);
      //   });

      setErrorInfo(errorArray);
    }
  };

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-2">
        <h5>Clients</h5>
      </div>
      <div className="container-xxl">
        <Box sx={{ height: 520, width: "100%" }}>
          <DataGridPro
            apiRef={apiRef}
            columns={showColumns}
            rows={rows}
            loading={loading}
            rowHeight={60}
            checkboxSelection
            disableSelectionOnClick
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterLogicOperator: GridLinkOperator.Or,
                },
              },
            }}
            components={{ Toolbar: QuickSearchToolbar }}
            disableColumnMenu
            onCellKeyDown={(params, events) => events.stopPropagation()}
          />
        </Box>
        <div className="row g-0 w-100 mt-3">
          <div className="d-flex">
            <MuiButton
              buttonText={"Next Step"}
              onClick={() => validateInvestments()}
              size={"medium"}
            />
          </div>
        </div>
        <ErrorComponent errorDetails={errorInfo} />
      </div>
    </React.Fragment>
  );
}
export default React.memo(MultiSelectTable);
