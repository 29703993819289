import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { getRequestHeaders } from "../utilities/functions/functions.js";
import useDebounce from "../Hooks/useDebounce.js";
import { HOST } from "../utilities/host-config/host-config.js";
import { getSignedURL } from "../utilities/functions/functions.js";
import { UserContext } from "../Contexts/UserContext.js";

const CancelToken = axios.CancelToken;

//Dev environment function
export default function useFundSearch(
  searchKeywords,
  fundMinimumAllocation,
  status,
  strategyType,
  setLoading
) {
  const [funds, setFunds] = useState([]);
  const cancelSource = useRef(null);
  const debouncedSearchTerm = useDebounce(searchKeywords, 200);

  useEffect(() => {
    setFunds([]);
  }, []);

  useEffect(() => {
    setFunds([]);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setFunds([]);
  }, [status]);

  useEffect(() => {
    setFunds([]);
  }, [fundMinimumAllocation]);

  useEffect(() => {
    setFunds([]);
  }, [strategyType]);

  useEffect(() => {
    cancelSource.current = CancelToken.source();
    setFunds([]);
    setLoading(true);
    const queryParams = {};
    let fundStrategies = {
      "Private Equity": "PRIVATE_EQUITY",
      "Direct Landing": "DIRECT_LANDING",
      "CLO Management": "CLO_MANAGEMENT",
      "Structured Credit": "STRUCTURED_CREDIT",
      "Co-Investment": "CO_INVESTMENT",
      "Fund of Funds": "FUND_OF_FUNDS",
      "Venture Capital": "VENTURE_CAPITAL",
      "PortCo": "PORTCO"
    };
    queryParams.search_keyword = debouncedSearchTerm;

    if (status !== "All") {
      queryParams.fund_investment_status = status;
    }

    if (fundMinimumAllocation !== "") {
      queryParams.fund_minimum_allocation = fundMinimumAllocation;
    }

    if (strategyType !== "All") {
      if (process.env.REACT_APP_DEBUG === "1") {
        console.log(fundStrategies[strategyType]);
      }
      queryParams.strategy_type = fundStrategies[strategyType];
    }

    async function getFunds(endPoint, queryParams, config) {
      setLoading(true);
      return axios
        .post(endPoint, queryParams, config)
        .then((response) => {
          const tempObject = response.data;

          if (response.data === null) {
            setFunds([]);
            setLoading(false);
          } else {
            setFunds([]);
            var logoLink = "";
            tempObject.map((fund) => {
              async function getUsableURLs() {
                logoLink = await getSignedURL("public", fund.logo_link);

                const fundInfo = {
                  id: fund.id,
                  link: fund.link,
                  fundName: fund.name ? fund.name : "ABCDE Fund IV",
                  logoLink: fund ? (logoLink ? logoLink : "") : "",
                  investorInterested:
                    fund.investor_fund_interested === null
                      ? false
                      : fund.investor_fund_interested,
                  firm: fund.firm ? fund.firm : "Thamesville Securities",
                  strategy: fund.link === 'dothan_concrete_investors_spv' ? 'PORTCO' : (fund.strategy_type
                    ? fund.strategy_type
                    : "PRIVATE_EQUITY"),
                  fundRestrictions: fund.restrictions
                    ? fund.restrictions
                    : "Nothing",
                  fundTerms: fund.fund_terms[0],
                  fundSize: fund.fund_terms[0].fund_size
                    ? fund.fund_terms[0].fund_size
                    : 2000000,
                  fundLength: 120,
                  fundInvestmentStatus: fund.investment_status,
                  enableRequestAllocation: fund.enable_request_allocation,
                  amountInvested: fund.fund_subscription_stats[0]
                    .committed_allocation_amount
                    ? fund.fund_subscription_stats[0]
                        .committed_allocation_amount
                    : 0,
                  minimumInvestment: fund.fund_terms[0]
                    .minimum_allocation_amount
                    ? fund.fund_terms[0].minimum_allocation_amount
                    : 0,
                  targetIRR: fund.fund_terms[0].target_return_upper_percent
                    ? fund.fund_terms[0].target_return_upper_percent
                    : 19,
                };
                setFunds((prevFunds) => [...prevFunds, fundInfo]);
                setLoading(false);
              }
              getUsableURLs();
            });
          }
        })
        .catch((error) => {
          setFunds([]);
          setLoading(false);
          if (process.env.REACT_APP_DEBUG === "1") console.log(error);
        });
    }
    getRequestHeaders().then((config) => {
      config.cancelToken = cancelSource.current.token;
      getFunds(
        HOST + "/wm-firm-ultra-fast-search-funds/",
        queryParams,
        config
      ).then();
    });

    return () => {
      cancelSource.current.cancel();
    };
  }, [debouncedSearchTerm, status, fundMinimumAllocation, strategyType]);

  return {
    funds: funds.sort((a, b) =>
      a.fundInvestmentStatus === "Upcoming" &&
      b.fundInvestmentStatus !== "Upcoming"
        ? -1
        : a.fundInvestmentStatus === b.fundInvestmentStatus
        ? 0
        : 1
    ),
  };
}
