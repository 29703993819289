import React, { useEffect, useState, useContext } from "react";
import StyledDateInputField from "../StyledDateInput/StyledDateInput.js";
import TextInputField from "../InputFields/TextInputField/TextInputField";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInputField from "./../InputFields/PhoneInputField/PhoneInputField";
import SelectInputField from "./../InputFields/SelectInputField/SelectInputField";
import { stateList } from "../stateList.js";
import { countryList } from "../countryList.js";
import MuiButton from "./../Buttons/MuiButton/MuiButton.js";
import { formatSSN } from "./../../utilities/functions/functions.js";
import EntityInputInformation from "./EntityInputInformation/EntityInputInformation.js";
import "./EntityInputInformation/Styles/Styles.scss";

function InfiniteInputComponent({
  inputFields,
  setInputFields,
  index,
  handleRemoveFields,
  setActiveTab,
  disabled,
  setHideButton,
  hideAlterButtons,
  relatedPartyType = "BO",
  accountType = "",
}) {
  const reducedCountryList = [
    { value: "United States", label: "United States" },
  ];

  const inputField = inputFields[index];

  const handleChangeInput = (index, value, name) => {
    const values = [...inputFields];
    values[index][name] = value;
    setInputFields(values);
  };

  useEffect(() => {
    if (inputFields.length === 0) {
      setHideButton(true);
    } else {
      setHideButton(false);
    }
  }, [inputFields]);

  useEffect(() => {
    handleChangeInput(index, "", "");
  }, []);

  const booleanList = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const relatedPartyTypeOptions = [
    { value: "Person", label: "Person" },
    { value: "Entity", label: "Entity" },
  ];

  return (
    <div className="container">
      <div className="row g-0 w-100">
        <div className="col-12">
          <form>
            <div className="row g-0 w-100 mb-3">
              <div className="col-12">
                <div className="row g-0 w-100 mb-2" key={index}>
                  <div className="col-12">
                    <div className="mt-3 mb-4"></div>
                    <div clasName="row g-0 w-100 mt-5 mb-3">
                      <SelectInputField
                        value={inputField.relatedPartyType}
                        setValue={(value) =>
                          handleChangeInput(index, value, "relatedPartyType")
                        }
                        valueError={inputField.relatedPartyTypeError}
                        setValueError={(value) =>
                          handleChangeInput(
                            index,
                            value,
                            "relatedPartyTypeError"
                          )
                        }
                        selectOptions={relatedPartyTypeOptions}
                        required={true}
                        fullWidth={true}
                        size={"small"}
                        id={"related-party-type-select"}
                        label={"Related Party Type"}
                        disabled={disabled}
                      />
                    </div>
                    {inputField.relatedPartyTypeError !== "" && (
                      <div className="row g-0 w-100 mb-3">
                        <span className="error-body-text-sm-bold">
                          {inputField.relatedPartyTypeError}
                        </span>
                      </div>
                    )}
                    {inputField.relatedPartyType === "Entity" && (
                      <div>
                        <EntityInputInformation
                          inputField={inputField}
                          handleChangeInput={handleChangeInput}
                          index={index}
                          disabled={disabled}
                          relatedPartyType={relatedPartyType}
                          accountType={accountType}
                        />
                      </div>
                    )}
                    {inputField.relatedPartyType === "Person" && (
                      <div>
                        <div className="row g-0 w-100 mt-4 mb-3">
                          <div className="col pe-2">
                            <div className="align-self-end mt-1">
                              <TextInputField
                                value={inputField.firstName}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "firstName")
                                }
                                setValueError={(e) => {
                                  inputField.firstNameError = e;
                                }}
                                valueError={inputField.firstNameError}
                                label={"First Name"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"account-name-input"}
                              />
                            </div>
                          </div>
                          <div className="col ps-2 pe-0">
                            <div className="align-self-end mt-1">
                              <TextInputField
                                value={inputField.lastName}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "lastName")
                                }
                                setValueError={(e) => {
                                  inputField.lastNameError = e;
                                }}
                                valueError={inputField.lastNameError}
                                label={"Last Name"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"account-name-input"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col pe-2">
                            <div className="align-self-end mt-1">
                              <TextInputField
                                value={inputField.email}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "email")
                                }
                                setValueError={(e) => {
                                  inputField.emailError = e;
                                }}
                                valueError={inputField.emailError}
                                label={"Email"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"account-name-input"}
                              />
                            </div>
                          </div>
                          <div className="col ps-2 pe-0">
                            <div className="align-self-end mt-1">
                              <PhoneInputField
                                value={inputField.phoneNumber}
                                setValue={(event) => {
                                  handleChangeInput(
                                    index,
                                    event,
                                    "phoneNumber"
                                  );
                                  inputField.phoneNumberError = "";
                                }}
                                setValueError={(e) => {
                                  inputField.phoneNumberError = e;
                                }}
                                valueError={inputField.phoneNumberError}
                                label={"Phone Number"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"account-name-input"}
                              />
                              {inputField.phoneNumberError &&
                                inputField.phoneNumberError !==
                                  "This field is required." && (
                                  <div className="SignupForm-field-error-text mt-1">
                                    <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                                      {inputField.phoneNumberError}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col pe-0">
                            <SelectInputField
                              value={inputField.isUSCitizen}
                              setValue={(event) => {
                                handleChangeInput(index, event, "isUSCitizen");
                                inputField.isUSCitizenError = "";
                              }}
                              valueError={inputField.isUSCitizenError}
                              setValueError={(e) => {
                                inputField.isUSCitizenError = e;
                              }}
                              selectOptions={booleanList}
                              label={"Is U.S. Citizen"}
                              required={true}
                              fullWidth={true}
                              size={"small"}
                              disabled={disabled}
                              id={"citizenship-input"}
                            />
                          </div>
                        </div>
                        {inputField.isUSCitizen === "No" && (
                          <div className="row g-0 w-100 mt-3">
                            <div className="col pe-0">
                              <SelectInputField
                                value={inputFields.citizenship}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "citizenship")
                                }
                                setValueError={(e) => {
                                  inputField.citizenshipError = e;
                                }}
                                valueError={inputFields.citizenshipError}
                                selectOptions={countryList}
                                label={"Citizenship"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                id={"client-citizenship-input"}
                              />
                            </div>
                          </div>
                        )}
                        <div className="row g-0 w-100 mb-3">
                          <div className="col-12">
                            <span className="add-account-step-subheader">
                              Address
                            </span>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col-12">
                            <div className="row g-0 w-100">
                              <div className="col-12">
                                <div className="align-self-end mt-1">
                                  <TextInputField
                                    value={inputField.addressLine1}
                                    setValue={(event) =>
                                      handleChangeInput(
                                        index,
                                        event,
                                        "addressLine1"
                                      )
                                    }
                                    setValueError={(e) => {
                                      inputField.addressLine1Error = e;
                                    }}
                                    valueError={inputField.addressLine1Error}
                                    label={"Address Line 1"}
                                    required={true}
                                    fullWidth={true}
                                    size={"small"}
                                    disabled={disabled}
                                    shrink={true}
                                    id={"account-name-input"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col-12">
                            <div className="row g-0 w-100">
                              <div className="col-12">
                                <TextInputField
                                  value={inputField.addressLine2}
                                  setValue={(event) =>
                                    handleChangeInput(
                                      index,
                                      event,
                                      "addressLine2"
                                    )
                                  }
                                  setValueError={(e) => {
                                    inputField.addressLine2Error = e;
                                  }}
                                  valueError={inputField.addressLine2Error}
                                  label={"Address Line 2"}
                                  required={true}
                                  fullWidth={true}
                                  size={"small"}
                                  disabled={disabled}
                                  shrink={true}
                                  id={"account-name-input"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className={true ? "col pe-2" : "col pe-0"}>
                            <div className="align-self-end mt-1">
                              <TextInputField
                                value={inputField.city}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "city")
                                }
                                setValueError={(e) => {
                                  inputField.cityError = e;
                                }}
                                valueError={inputField.cityError}
                                label={"City"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"account-name-input"}
                              />
                              {inputField.cityError &&
                                inputField.cityError !==
                                  "This field is required." && (
                                  <div className="SignupForm-field-error-text mt-1">
                                    <span className="">
                                      {inputField.cityError}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                          {inputField.country === "United States" && (
                            <div className="col ps-2 pe-0">
                              <div className="align-self-end mt-1">
                                <SelectInputField
                                  value={inputField.state}
                                  setValue={(event) => {
                                    handleChangeInput(index, event, "state");
                                    inputField.stateError = "";
                                  }}
                                  valueError={inputField.stateError}
                                  setValueError={(e) => {
                                    inputField.stateError = e;
                                  }}
                                  selectOptions={stateList}
                                  required={true}
                                  fullWidth={true}
                                  size={"small"}
                                  disabled={disabled}
                                  shrink={true}
                                  id={"formation-country-select"}
                                  label={" State"}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          {/* <div className="col pe-2">
                        <div className="align-self-end mt-1">
                          <SelectInputField
                            value={inputField.country}
                            setValue={(event) => {
                              handleChangeInput(index, event, "country");
                              inputField.countryError = "";
                            }}
                            valueError={inputField.countryError}
                            setValueError={(e) => {
                              inputField.countryError = e;
                            }}
                            selectOptions={countryList}
                            required={true}
                            fullWidth={true}
                            size={"small"}
                            disabled={disabled}
                            shrink={true}
                            id={"formation-country-select"}
                            label={"Country"}
                          />
                        </div>
                      </div> */}
                          <div className="col ps-0 pe-0">
                            <div className="col pe-3">
                              <SelectInputField
                                value={inputField.country}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "country")
                                }
                                setValueError={(e) => {
                                  inputField.countryError = e;
                                }}
                                valueError={inputField.countryError}
                                label={"Country"}
                                selectOptions={reducedCountryList}
                                required={true}
                                fullWidth={true}
                                shrink={true}
                                size={"small"}
                                disabled={disabled}
                                id={"client-country-input"}
                              />
                            </div>
                            <div className="align-self-end mt-3">
                              <TextInputField
                                value={inputField.postalCode}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "postalCode")
                                }
                                setValueError={(e) => {
                                  inputField.postalCodeError = e;
                                }}
                                valueError={inputField.postalCodeError}
                                label={"Postal Code"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"postal-code-name-input"}
                                mask={{
                                  maskPattern: "999999999999",
                                  maskFormatter: (e) => e,
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row g-0 w-100 mb-3">
                          <div className="col-12">
                            <span className="add-account-step-subheader">
                              Personal Information
                            </span>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col pe-2">
                            <div className="align-self-end mt-1">
                              <StyledDateInputField
                                value={inputField.dateOfBirth}
                                setValue={(event) =>
                                  handleChangeInput(index, event, "dateOfBirth")
                                }
                                valueError={inputField.dateOfBirthError}
                                setValueError={(e) => {
                                  inputField.dateOfBirthError = e;
                                }}
                                label={"Date Of Birth"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"individual-account-dob-input"}
                              />
                            </div>
                          </div>
                          <div className="col ps-2 pe-0">
                            <div className="align-self-end mt-1">
                              {inputField.country === "United States" ? (
                                <React.Fragment>
                                  <TextInputField
                                    value={inputField.idNum}
                                    setValue={(event) => {
                                      handleChangeInput(index, event, "idNum");
                                      inputField.idNumError = "";
                                    }}
                                    setValueError={(e) => {
                                      inputField.idNumError = e;
                                    }}
                                    mask={{
                                      maskFormatter: formatSSN,
                                      maskPattern: "999-99-9999",
                                    }}
                                    valueError={inputField.idNumError}
                                    label={"SSN"}
                                    required={true}
                                    fullWidth={true}
                                    size={"small"}
                                    disabled={disabled}
                                    shrink={true}
                                    id={"account-name-input"}
                                  />
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <TextInputField
                                    value={inputField.idNum}
                                    setValue={(event) => {
                                      handleChangeInput(index, event, "idNum");
                                      inputField.idNumError = "";
                                    }}
                                    setValueError={(e) => {
                                      inputField.idNumError = e;
                                    }}
                                    valueError={inputField.idNumError}
                                    label={"Passport Number"}
                                    required={true}
                                    fullWidth={true}
                                    size={"small"}
                                    disabled={disabled}
                                    shrink={true}
                                    id={"account-name-input"}
                                  />
                                  {inputField.idNumError &&
                                    inputField.idNumError !==
                                      "This field is required." && (
                                      <div className="SignupForm-field-error-text mt-1">
                                        <span className="">
                                          {inputField.idNumError}
                                        </span>
                                      </div>
                                    )}
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row g-0 w-100 mb-3">
                          <div className="col-12">
                            <span className="add-account-step-subheader">
                              Percent Ownership
                            </span>
                          </div>

                          <div className="align-self-end mt-1 mt-3">
                            <div className="col ps-0 pe-0 w-25">
                              <TextInputField
                                value={inputField.percentOwnership}
                                setValue={(event) => {
                                  handleChangeInput(
                                    index,
                                    event,
                                    "percentOwnership"
                                  );
                                  inputField.percentOwnershipError = "";
                                }}
                                setValueError={(e) => {
                                  inputField.percentOwnershipError = e;
                                }}
                                mask={{
                                  maskPattern: "999",
                                  maskFormatter: (e) =>
                                    parseInt(e) <= 100 ? e : e.slice(0, 2),
                                }}
                                valueError={inputField.percentOwnershipError}
                                label={"Percent Ownership"}
                                required={true}
                                fullWidth={true}
                                size={"small"}
                                disabled={disabled}
                                shrink={true}
                                id={"account-name-input"}
                              />
                            </div>
                          </div>
                        </div>
                        {!hideAlterButtons &&
                          inputFields.length !== 1 &&
                          !disabled && (
                            <div className="row g-0 w-100 mb-3">
                              <div className="d-flex">
                                <div className="d-flex">
                                  <MuiButton
                                    buttonText={"Remove this person"}
                                    onClick={() => handleRemoveFields(index)}
                                    size={"large"}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default InfiniteInputComponent;
