import { useState, useEffect, useContext } from "react";
import { UserContext } from "../Contexts/UserContext";
import { getFund } from "../APIs/FundDetailsAPI.js";
import { getSignedURL } from "../utilities/functions/functions.js";

export default function GetFund(
  fundLink,
  setLoading,
  set403Error,
  set404Error
) {
  const [fund, setFund] = useState(null);

  const { user } = useContext(UserContext);

  useEffect(() => {
    async function getFundInfo() {
      try {
        const response = await getFund(fundLink);

        if (process.env.REACT_APP_DEBUG === "1") {
          console.log(response.data);
        }
        const fundData = response.data;
        var firmLogoLinkUrl = "";
        async function getUsableURLs() {
          firmLogoLinkUrl = await getSignedURL(
            "public",
            fundData.originator_firm.logo_link
          );

          setFund({
            firmName: fundData.originator_firm.name,
            logoLink: firmLogoLinkUrl,
            link: fundData.link,
            fundName: fundData.name,
            firmAUM: fundData.originator_firm.firm_aum,
            tagline: fundData.tagline,
            investmentStatus: fundData.investment_status,
            registrationType: fundData.registration_type.type,
            display: fundData.display_flag,
            fundStrategy: fundData.link !== 'dothan_concrete_investors_spv' ? fundData.strategy_type : ({ 'type': 'PORTCO' }),
            fundOverview: fundData.short_description,
            fundDocs: fundData.fund_documents,
            targetFundLength: fundData.fund_terms[0].target_length_months,
            targetIRR: fundData.fund_terms[0].target_return_upper_percent,
            placementFee: fundData.fund_terms[0].upfront_management_fee_percent,
            distributionFee:
              fundData.fund_terms[0].ongoing_distribution_fee_percent,
            deckLink: fundData.deck_link,
            // fundraisingStartDate: fundData.funding_start_date,
            // fundraisingEndDate: fundData.funding_end_date,
            // fundStartDate: fundData.fund_start_date,
            // fundEndDate: fundData.fund_end_date,
            // targetInvestmentPeriod: fundData.investment_period_months,
            incorporationCountry: fundData.incorporation_country,
            targetInvestmentPeriod: 120,
            fundSize: fundData.originating_fund.originating_fund_terms[0].fund_size,
            minimumInvestment: fundData.fund_terms[0].minimum_allocation_amount,
            maximumInvestment: fundData.fund_terms[0].maximum_allocation_amount,
            amountInvested:
              fundData.fund_subscriptions_stats !== undefined
                ? fundData.fund_subscriptions_stats[0]
                  .committed_allocation_amount
                : 0,
            allocationSize: fundData.fund_terms[0].fund_size,
            extensionCategories: fundData.extension_categories
              ? fundData.extension_categories
              : [],
            referenceTerms: fundData.fund_reference_terms
              ? fundData.fund_reference_terms
              : [],
            targetReturnMultiple: fundData.fund_terms[0].target_return_multiple,
            enableRequestAllocation: fundData.enable_request_allocation,
            legacyPricingStructure:
              fundData.fund_terms[0].legacy_pricing_structure,
            fundDueDiligenceQuestions:
              fundData.fund_due_diligence_questions !== null &&
                fundData.fund_due_diligence_questions !== undefined
                ? fundData.fund_due_diligence_questions
                : [],
          });
          setLoading(false);
        }
        getUsableURLs();
      } catch (err) {
        if (err.message === "Request failed with status code 403") {
          set403Error(true);
        } else if (err.message === "Request failed with status code 404") {
          set404Error(true);
        }

        if (process.env.REACT_APP_DEBUG) console.error(err);
        setLoading(false);
        // console.log(err);
      }
      // setLoading(false);
    }
    getFundInfo();
  }, [fundLink, setFund]);

  return { fund };
}
