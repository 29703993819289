import React, { useState, useEffect } from "react";
import "../Styles/Styles.scss";
import TextInputField from "../../../InputFields/TextInputField/TextInputField";
import SelectInputField from "../../../InputFields/SelectInputField/SelectInputField";
import PhoneInputField from "../../../InputFields/PhoneInputField/PhoneInputField";
import { stateList } from "../../../stateList";
import UploadButton from "../../../Buttons/UploadButton/UploadButton";
import StyledDateInputField from "../../../StyledDateInput/StyledDateInput";
import { formatSSN } from "../../../../utilities/functions/functions";

const IrrevocableTrustRelatedPartyInputInformation = ({
  index,
  inputField,
  relatedPartyType,
  handleChangeInput,
  handleFileUpload,
  disabled,
  yesNoOptions,
}) => {
  const relatedPartyTypeOptions = [
    { value: "Person", label: "Person" },
    { value: "Entity", label: "Entity" },
  ];

  const trusteeOptions = [
    // { value: "LT", label: "Revocable Trust" },
    // { value: "TRUST", label: "Irrevocable Trust" },
    { value: "SM_LLC", label: "Single-member LLC" },
    { value: "LLC", label: "LLC" },
    { value: "GP", label: "General Partnership" },
    { value: "LP", label: "Limited Partnership" },
    { value: "C-CORP", label: "C-Corp" },
    { value: "S-CORP", label: "S-Corp" },
  ];

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-3">
        <h6>Trustee Information</h6>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-0">
          <div className="align-self-end mt-1">
            <SelectInputField
              value={inputField.entityNestedControlEntityTrusteeType}
              setValue={(value) => {
                handleChangeInput(
                  index,
                  value,
                  "entityNestedControlEntityTrusteeType"
                );
                inputField.entityNestedControlEntityTrusteeTypeError = "";
              }}
              valueError={inputField.entityNestedControlEntityTrusteeTypeError}
              setValueError={(value) =>
                handleChangeInput(
                  index,
                  value,
                  "entityNestedControlEntityTrusteeTypeError"
                )
              }
              selectOptions={relatedPartyTypeOptions}
              required={true}
              fullWidth={true}
              size={"small"}
              disabled={disabled}
              id={"entity-nested-control-entity-trustee-type-select"}
              label={"Is the trustee a person or entity?"}
            />
          </div>
        </div>
      </div>
      {inputField.entityNestedControlEntityTrusteeType !== "Entity" ? (
        <React.Fragment>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-0">
              <div className="align-self-end mt-1">
                <h6>Trustee Person Information</h6>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityControlPersonFirstName}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityControlPersonFirstName"
                    );
                    inputField.entityControlPersonFirstNameError = "";
                  }}
                  setValueError={(e) => {
                    inputField.entityControlPersonFirstNameError = e;
                  }}
                  valueError={inputField.entityControlPersonFirstNameError}
                  label={"First Name"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={"entity-control-person-first-name-input"}
                />
              </div>
            </div>
            <div className="col ps-2 pe-0">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityControlPersonLastName}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityControlPersonLastName"
                    );
                    inputField.entityControlPersonLastNameError = "";
                  }}
                  setValueError={(e) => {
                    inputField.entityControlPersonLastNameError = e;
                  }}
                  valueError={inputField.entityControlPersonLastNameError}
                  label={"Last Name"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={"entity-control-person-last-name-input"}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityControlPersonEmail}
                  setValue={(event) => {
                    handleChangeInput(index, event, "entityControlPersonEmail");
                    inputField.entityControlPersonEmailError = "";
                  }}
                  setValueError={(e) => {
                    inputField.entityControlPersonEmailError = e;
                  }}
                  valueError={inputField.entityControlPersonEmailError}
                  label={"Email"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={"entity-control-person-email-input"}
                />
              </div>
            </div>
            <div className="col ps-2 pe-0">
              <div className="align-self-end mt-1">
                <PhoneInputField
                  value={inputField.entityControlPersonPhoneNumber}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityControlPersonPhoneNumber"
                    );
                    inputField.entityControlPersonPhoneNumberError = "";
                  }}
                  setValueError={(e) => {
                    inputField.entityControlPersonPhoneNumberError = e;
                  }}
                  valueError={inputField.entityControlPersonPhoneNumberError}
                  label={"Phone Number"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={"entity-control-person-phone-number-input"}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <SelectInputField
                  value={inputField.entityControlPersonIsUSBased}
                  setValue={(value) => {
                    handleChangeInput(
                      index,
                      value,
                      "entityControlPersonIsUSBased"
                    );
                    inputField.entityControlPersonIsUSBasedError = "";
                  }}
                  valueError={inputField.entityControlPersonIsUSBasedError}
                  setValueError={(value) =>
                    handleChangeInput(
                      index,
                      value,
                      "entityControlPersonIsUSBasedError"
                    )
                  }
                  selectOptions={yesNoOptions}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  id={"entity-control-person-is-us-based-select"}
                  label={"Is US Based?"}
                />
              </div>
            </div>
            <div className="col ps-2 pe-0">
              <div className="align-self-end mt-1">
                <SelectInputField
                  value={inputField.entityControlPersonIsUSCitizen}
                  setValue={(value) => {
                    handleChangeInput(
                      index,
                      value,
                      "entityControlPersonIsUSCitizen"
                    );
                    inputField.entityControlPersonIsUSCitizenError = "";
                  }}
                  valueError={inputField.entityControlPersonIsUSCitizenError}
                  setValueError={(value) =>
                    handleChangeInput(
                      index,
                      value,
                      "entityControlPersonIsUSCitizenError"
                    )
                  }
                  selectOptions={yesNoOptions}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  id={"entity-control-person-is-us-citizen-select"}
                  label={"Is US Citizen?"}
                />
              </div>
            </div>
          </div>
          {/* {!disabled && (
            <React.Fragment>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-0">
                  <span className="body-text-sm-semibold">
                    Do you have access to the personally identifiable
                    information for a control person of this entity? (i.e. SSN,
                    DOB, etc.)
                  </span>
                </div>
              </div>
              <div className="row g-0 w-100 mb-3">
                <div className="col-6">
                  <label
                    for={
                      "entityControlPersonPIIAvailableId" +
                      index +
                      relatedPartyType
                    }
                    className={"radio"}
                  >
                    <input
                      type={"radio"}
                      name={"entityControlPersonPIIAvailableRadio"}
                      id={
                        "entityControlPersonPIIAvailableId" +
                        index +
                        relatedPartyType
                      }
                      className={"radio__input"}
                      checked={
                        inputField.entityControlPersonPIIAvailable === "Yes"
                      }
                      onClick={() => {
                        if (!disabled) {
                          handleChangeInput(
                            index,
                            "Yes",
                            "entityControlPersonPIIAvailable"
                          );
                          inputField.entityControlPersonPIIAvailableError = "";
                        }
                      }}
                    />
                    <div className="radio__radio"></div>
                    <span className="qualification-question-text ms-2">
                      Yes
                    </span>
                  </label>
                </div>
                <div className="col-6">
                  <label
                    for={
                      "entityControlPersonPIINotAvailableId" +
                      index +
                      relatedPartyType
                    }
                    className={"radio"}
                  >
                    <input
                      type={"radio"}
                      name={"entityControlPersonPIINotAvailableRadio"}
                      id={
                        "entityControlPersonPIINotAvailableId" +
                        index +
                        relatedPartyType
                      }
                      className={"radio__input"}
                      checked={
                        inputField.entityControlPersonPIIAvailable === "No"
                      }
                      onClick={() => {
                        if (!disabled) {
                          handleChangeInput(
                            index,
                            "No",
                            "entityControlPersonPIIAvailable"
                          );
                          inputField.entityControlPersonPIIAvailableError = "";
                        }
                      }}
                    />
                    <div className="radio__radio"></div>
                    <span className="qualification-question-text ms-2">No</span>
                  </label>
                </div>
              </div>
            </React.Fragment>
          )} */}
          {/* {inputField.entityControlPersonPIIAvailableError !== "" && (
            <div className="row g-0 w-100 mb-3">
              <div className="SignupForm-field-error-text mt-1">
                <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                  {inputField.entityControlPersonPIIAvailableError}
                </span>
              </div>
            </div>
          )} */}
          <React.Fragment>
            <div className="row g-0 w-100 mb-3">
              <div className="col pe-0">
                <div className="align-self-end mt-1">
                  <h6>Trustee Personal Information</h6>
                  {/* {relatedPartyType === "TRT" ? (
                      <h6>
                        Trustee Entity Control Person Personal Information
                      </h6>
                    ) : (
                      <h6>
                        Beneficial Owner Entity Control Person Personal
                        Information
                      </h6>
                    )} */}
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col pe-2">
                <div className="align-self-end mt-1">
                  <TextInputField
                    value={inputField.entityControlPersonAddressLine1}
                    setValue={(event) => {
                      handleChangeInput(
                        index,
                        event,
                        "entityControlPersonAddressLine1"
                      );
                      inputField.entityControlPersonAddressLine1Error = "";
                    }}
                    setValueError={(e) => {
                      inputField.entityControlPersonAddressLine1Error = e;
                    }}
                    valueError={inputField.entityControlPersonAddressLine1Error}
                    label={"Address Line 1"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={disabled}
                    shrink={true}
                    id={"entity-control-person-address-line-1-input"}
                  />
                </div>
              </div>
              <div className="col ps-2 pe-0">
                <div className="align-self-end mt-1">
                  <TextInputField
                    value={inputField.entityControlPersonAddressLine2}
                    setValue={(event) => {
                      handleChangeInput(
                        index,
                        event,
                        "entityControlPersonAddressLine2"
                      );
                      inputField.entityControlPersonAddressLine2Error = "";
                    }}
                    setValueError={(e) => {
                      inputField.entityControlPersonAddressLine2Error = e;
                    }}
                    valueError={inputField.entityControlPersonAddressLine2Error}
                    label={"Address Line 2"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={disabled}
                    shrink={true}
                    id={"entity-control-person-address-line-2-input"}
                  />
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col pe-2">
                <div className="align-self-end mt-1">
                  <TextInputField
                    value={inputField.entityControlPersonCity}
                    setValue={(event) => {
                      handleChangeInput(
                        index,
                        event,
                        "entityControlPersonCity"
                      );
                      inputField.entityControlPersonCityError = "";
                    }}
                    setValueError={(e) => {
                      inputField.entityControlPersonCityError = e;
                    }}
                    valueError={inputField.entityControlPersonCityError}
                    label={"City"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={disabled}
                    shrink={true}
                    id={"entity-control-person-city-input"}
                  />
                </div>
              </div>
              <div className="col ps-2 pe-0">
                <div className="align-self-end mt-1">
                  <SelectInputField
                    value={inputField.entityControlPersonState}
                    setValue={(event) => {
                      handleChangeInput(
                        index,
                        event,
                        "entityControlPersonState"
                      );
                      inputField.entityControlPersonStateError = "";
                    }}
                    valueError={inputField.entityControlPersonStateError}
                    setValueError={(e) => {
                      inputField.entityControlPersonStateError = e;
                    }}
                    selectOptions={stateList}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={disabled}
                    shrink={true}
                    id={"entity-control-person-state-select"}
                    label={"State"}
                  />
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col pe-0">
                <div className="align-self-end mt-1">
                  <TextInputField
                    value={inputField.entityControlPersonPostalCode}
                    setValue={(event) => {
                      handleChangeInput(
                        index,
                        event,
                        "entityControlPersonPostalCode"
                      );
                      inputField.entityControlPersonPostalCodeError = "";
                    }}
                    setValueError={(e) => {
                      inputField.entityControlPersonPostalCodeError = e;
                    }}
                    valueError={inputField.entityControlPersonPostalCodeError}
                    label={"Postal Code"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={disabled}
                    shrink={true}
                    id={"entity-control-person-postal-code-input"}
                    mask={{
                      maskPattern: "999999999999",
                      maskFormatter: (e) => e,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row g-0 w-100 mb-3">
              <div className="col pe-2">
                <div className="align-self-end mt-1">
                  <StyledDateInputField
                    value={inputField.entityControlPersonDOB}
                    setValue={(event) => {
                      handleChangeInput(index, event, "entityControlPersonDOB");
                      inputField.entityControlPersonDOBError = "";
                    }}
                    valueError={inputField.entityControlPersonDOBError}
                    setValueError={(e) => {
                      inputField.entityControlPersonDOBError = e;
                    }}
                    label={"Date Of Birth"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={disabled}
                    shrink={true}
                    id={"entity-control-person-dob-input"}
                  />
                </div>
              </div>
              <div className="col ps-2 pe-0">
                <div className="align-self-end mt-1">
                  <TextInputField
                    value={inputField.entityControlPersonTaxIDNumber}
                    setValue={(event) => {
                      handleChangeInput(
                        index,
                        event,
                        "entityControlPersonTaxIDNumber"
                      );
                      inputField.entityControlPersonTaxIDNumberError = "";
                    }}
                    setValueError={(e) => {
                      inputField.entityControlPersonTaxIDNumberError = e;
                    }}
                    mask={{
                      maskFormatter: formatSSN,
                      maskPattern: "999-99-9999",
                    }}
                    valueError={inputField.entityControlPersonTaxIDNumberError}
                    label={"SSN"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={disabled}
                    shrink={true}
                    id={"entity-control-person-tax-id-number-input"}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
          {!disabled && (
            <React.Fragment>
              <div className="row g-0 w-100 mb-2">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    <h6>Additional Required Trust Documentation</h6>
                    {/* {relatedPartyType === "TRT" ? (
                      <h6>Additional Required Trustee Entity Documentation</h6>
                    ) : (
                      <h6>
                        Additional Required Beneficial Owner Entity
                        Documentation
                      </h6>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100 mb-2">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    <span className="body-text-sm-semibold">
                      Please upload the following documents for the trust
                      (Certificate of Formation, Proof of Address):
                      {/* {relatedPartyType === "TRT"
                        ? "Please upload the following documents for this trustee entity (Certificate of Formation, Proof of Address):"
                        : "Please upload the following documents for this beneficial owner entity (Certificate of Formation, Proof of Address):"} */}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100 mb-1">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    {inputField.entityCertificateOfFormation === "" ? (
                      <UploadButton
                        text={"Upload Certificate of Formation"}
                        onChange={handleFileUpload}
                        inputField={"entityCertificateOfFormation"}
                        label={index + relatedPartyType + "somethingEntity"}
                      />
                    ) : (
                      <div className="d-flex">
                        <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                          <span className="body-text-sm-semibold">
                            Certificate of Formation Uploaded
                          </span>
                        </div>
                        <div className="align-self-center align-items-center pt-auto pb-auto">
                          <UploadButton
                            text={"Change Certificate of Formation"}
                            onChange={handleFileUpload}
                            inputField={"entityCertificateOfFormation"}
                            label={index + relatedPartyType + "somethingEntity"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {inputField.entityCertificateOfFormationError !== "" && (
                <div className="row g-0 w-100 mb-3">
                  <div className="SignupForm-field-error-text mt-1">
                    <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                      {inputField.entityCertificateOfFormationError}
                    </span>
                  </div>
                </div>
              )}
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    {inputField.entityProofOfAddress === "" ? (
                      <UploadButton
                        text={"Upload Proof of Address"}
                        onChange={handleFileUpload}
                        inputField={"entityProofOfAddress"}
                        label={index + relatedPartyType + "somethingEntity"}
                      />
                    ) : (
                      <div className="d-flex">
                        <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                          <span className="body-text-sm-semibold">
                            Proof of Address Uploaded
                          </span>
                        </div>
                        <div className="align-self-center align-items-center pt-auto pb-auto">
                          <UploadButton
                            text={"Change Proof of Address"}
                            onChange={handleFileUpload}
                            inputField={"entityProofOfAddress"}
                            label={index + relatedPartyType + "somethingEntity"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {inputField.entityProofOfAddressError !== "" && (
                <div className="row g-0 w-100 mb-3">
                  <div className="SignupForm-field-error-text mt-1">
                    <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                      {inputField.entityProofOfAddressError}
                    </span>
                  </div>
                </div>
              )}
              <React.Fragment>
                <div className="row g-0 w-100 mb-2">
                  <div className="col pe-0">
                    <div className="align-self-end mt-1">
                      <h6>Additional Required Trustee Documentation</h6>
                    </div>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-1">
                  <div className="col pe-0">
                    <div className="align-self-end mt-1">
                      <span className="body-text-sm-semibold">
                        Please upload the following documents for the trustee of
                        the trust (Passport, Proof of Address):
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row g-0 w-100 mb-1">
                  <div className="col pe-0">
                    <div className="align-self-end mt-1">
                      {inputField.entityControlPersonPassportDocument === "" ? (
                        <UploadButton
                          text={"Upload Passport"}
                          onChange={handleFileUpload}
                          inputField={"entityControlPersonPassportDocument"}
                          label={index + relatedPartyType + "something"}
                        />
                      ) : (
                        <div className="d-flex">
                          <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                            <span className="body-text-sm-semibold">
                              Passport Uploaded
                            </span>
                          </div>
                          <div className="align-self-center align-items-center pt-auto pb-auto">
                            <UploadButton
                              text={"Change Passport"}
                              onChange={handleFileUpload}
                              inputField={"entityControlPersonPassportDocument"}
                              label={index + relatedPartyType + "something"}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {inputField.entityControlPersonPassportDocumentError !== "" && (
                  <div className="row g-0 w-100 mb-3">
                    <div className="SignupForm-field-error-text mt-1">
                      <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                        {inputField.entityControlPersonPassportDocumentError}
                      </span>
                    </div>
                  </div>
                )}
                <div className="row g-0 w-100 mb-3">
                  <div className="col pe-0">
                    <div className="align-self-end mt-1">
                      {inputField.entityControlPersonProofOfAddress === "" ? (
                        <UploadButton
                          text={"Upload Proof of Address"}
                          onChange={handleFileUpload}
                          inputField={"entityControlPersonProofOfAddress"}
                          label={index + relatedPartyType + "something"}
                        />
                      ) : (
                        <div className="d-flex">
                          <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                            <span className="body-text-sm-semibold">
                              Proof of Address Uploaded
                            </span>
                          </div>
                          <div className="align-self-center align-items-center pt-auto pb-auto">
                            <UploadButton
                              text={"Update Proof of Address"}
                              onChange={handleFileUpload}
                              inputField={"entityControlPersonProofOfAddress"}
                              label={index + relatedPartyType + "something"}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {inputField.entityControlPersonProofOfAddressError !== "" && (
                  <div className="row g-0 w-100 mb-3">
                    <div className="SignupForm-field-error-text mt-1">
                      <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                        {inputField.entityControlPersonProofOfAddressError}
                      </span>
                    </div>
                  </div>
                )}
              </React.Fragment>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="row g-0 w-100 mb-3">
            <h6>Trustee Entity Information</h6>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <SelectInputField
                  value={inputField.entityNestedControlEntityType}
                  setValue={(value) => {
                    handleChangeInput(
                      index,
                      value,
                      "entityNestedControlEntityType"
                    );
                    inputField.entityNestedControlEntityTypeError = "";
                  }}
                  valueError={inputField.entityNestedControlEntityTypeError}
                  setValueError={(value) =>
                    handleChangeInput(
                      index,
                      value,
                      "entityNestedControlEntityTypeError"
                    )
                  }
                  selectOptions={trusteeOptions}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  id={"entity-nested-control-entity-type-select"}
                  label={"Entity Type"}
                />
              </div>
            </div>
            <div className="col ps-2 pe-0">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityNestedControlEntityName}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityName"
                    );
                    inputField.entityNestedControlEntityNameError = "";
                  }}
                  setValueError={(e) => {
                    inputField.entityNestedControlEntityNameError = e;
                  }}
                  valueError={inputField.entityNestedControlEntityNameError}
                  label={"Entity Legal Name"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={"entity-nested-control-entity-name-input"}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityNestedControlEntityPercentOwned}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityPercentOwned"
                    );
                    inputField.entityNestedControlEntityPercentOwnedError = "";
                  }}
                  setValueError={(e) => {
                    inputField.entityNestedControlEntityPercentOwnedError = e;
                  }}
                  valueError={
                    inputField.entityNestedControlEntityPercentOwnedError
                  }
                  label={"Percent Ownership"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={"entity-nested-control-entity-percent-owned-input"}
                />
              </div>
            </div>
            <div className="col ps-2 pe-0">
              <div className="align-self-end mt-1">
                <SelectInputField
                  value={
                    inputField.entityNestedControlEntityStateOfIncorporation
                  }
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityStateOfIncorporation"
                    );
                    inputField.entityNestedControlEntityStateOfIncorporationError =
                      "";
                  }}
                  valueError={
                    inputField.entityNestedControlEntityStateOfIncorporationError
                  }
                  setValueError={(e) => {
                    inputField.entityNestedControlEntityStateOfIncorporationError =
                      e;
                  }}
                  selectOptions={stateList}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={
                    "entity-nested-control-entity-state-of-incorporation-select"
                  }
                  label={"State of Incorporation"}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div
              className={
                inputField.entityNestedControlEntityType !== "SM_LLC"
                  ? "col pe-2"
                  : "col pe-0"
              }
            >
              <div className="align-self-end mt-1">
                <PhoneInputField
                  value={inputField.entityNestedControlEntityPhoneNumber}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityPhoneNumber"
                    );
                    inputField.entityNestedControlEntityPhoneNumberError = "";
                  }}
                  setValueError={(e) => {
                    inputField.entityNestedControlEntityPhoneNumberError = e;
                  }}
                  valueError={
                    inputField.entityNestedControlEntityPhoneNumberError
                  }
                  label={"Business Phone"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={"entity-nested-control-entity-phone-number-input"}
                />
              </div>
            </div>
            {inputField.entityNestedControlEntityType !== "SM_LLC" && (
              <div className={"col ps-2 pe-0"}>
                <div className="align-self-end mt-1">
                  <TextInputField
                    value={inputField.entityNestedControlEntityTaxIDNumber}
                    setValue={(event) => {
                      handleChangeInput(
                        index,
                        event,
                        "entityNestedControlEntityTaxIDNumber"
                      );
                      inputField.entityNestedControlEntityTaxIDNumberError = "";
                    }}
                    valueError={
                      inputField.entityNestedControlEntityTaxIDNumberError
                    }
                    setValueError={(event) =>
                      handleChangeInput(
                        index,
                        event,
                        "entityNestedControlEntityTaxIDNumberError"
                      )
                    }
                    label={"Entity EIN"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={disabled}
                    id={"entity-nested-control-entity-tax-id-number-input"}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityNestedControlEntityAddressLine1}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityAddressLine1"
                    );
                    inputField.entityNestedControlEntityAddressLine1Error = "";
                  }}
                  valueError={
                    inputField.entityNestedControlEntityAddressLine1Error
                  }
                  setValueError={(event) =>
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityAddressLine1Error"
                    )
                  }
                  label={"Address Line 1"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  id={"entity-nested-control-entity-address-line-1-input"}
                />
              </div>
            </div>
            <div className="col ps-2 pe-0">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityNestedControlEntityAddressLine2}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityAddressLine2"
                    );
                    inputField.entityNestedControlEntityAddressLine2Error = "";
                  }}
                  valueError={
                    inputField.entityNestedControlEntityAddressLine2Error
                  }
                  setValueError={(event) =>
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityAddressLine2Error"
                    )
                  }
                  label={"Address Line 2"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  id={"entity-nested-control-entity-address-line-2-input"}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityNestedControlEntityCity}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityCity"
                    );
                    inputField.entityNestedControlEntityCityError = "";
                  }}
                  valueError={inputField.entityNestedControlEntityCityError}
                  setValueError={(event) =>
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityCityError"
                    )
                  }
                  label={"City"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  id={"entity-nested-control-entity-city-input"}
                />
              </div>
            </div>
            <div className="col ps-2 pe-0">
              <div className="align-self-end mt-1">
                <SelectInputField
                  value={inputField.entityNestedControlEntityState}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityState"
                    );
                    inputField.entityNestedControlEntityStateError = "";
                  }}
                  valueError={inputField.entityNestedControlEntityStateError}
                  setValueError={(e) => {
                    inputField.entityNestedControlEntityStateError = e;
                  }}
                  selectOptions={stateList}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={"entity-nested-control-entity-state-select"}
                  label={"State"}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-0">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityNestedControlEntityPostalCode}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityPostalCode"
                    );
                    inputField.entityNestedControlEntityPostalCodeError = "";
                  }}
                  valueError={
                    inputField.entityNestedControlEntityPostalCodeError
                  }
                  setValueError={(event) =>
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityPostalCodeError"
                    )
                  }
                  label={"Postal Code"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  id={"entity-nested-control-entity-postal-code-input"}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-0">
              <div className="align-self-end mt-1">
                <SelectInputField
                  value={
                    inputField.entityNestedControlEntityIsTrustManagementCompany
                  }
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityIsTrustManagementCompany"
                    );
                    inputField.entityNestedControlEntityIsTrustManagementCompanyError =
                      "";
                  }}
                  valueError={
                    inputField.entityNestedControlEntityIsTrustManagementCompanyError
                  }
                  setValueError={(e) => {
                    inputField.entityNestedControlEntityIsTrustManagementCompanyError =
                      e;
                  }}
                  selectOptions={yesNoOptions}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={
                    "entity-nested-control-entity-is-trust-management-company-select"
                  }
                  label={"Is the entity a trust company?"}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-0">
              <div className="align-self-end mt-1">
                <h6>Trustee Entity Control Person Information</h6>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={
                    inputField.entityNestedControlEntityControlPersonFirstName
                  }
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityControlPersonFirstName"
                    );
                    inputField.entityNestedControlEntityControlPersonFirstNameError =
                      "";
                  }}
                  valueError={
                    inputField.entityNestedControlEntityControlPersonFirstNameError
                  }
                  setValueError={(event) =>
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityControlPersonFirstNameError"
                    )
                  }
                  label={"First Name"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  id={
                    "entity-nested-control-entity-control-person-first-name-input"
                  }
                />
              </div>
            </div>
            <div className="col ps-2 pe-0">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={
                    inputField.entityNestedControlEntityControlPersonLastName
                  }
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityControlPersonLastName"
                    );
                    inputField.entityNestedControlEntityControlPersonLastNameError =
                      "";
                  }}
                  valueError={
                    inputField.entityNestedControlEntityControlPersonLastNameError
                  }
                  setValueError={(event) =>
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityControlPersonLastNameError"
                    )
                  }
                  label={"Last Name"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  id={
                    "entity-nested-control-entity-control-person-last-name-input"
                  }
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <TextInputField
                  value={inputField.entityNestedControlEntityControlPersonEmail}
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityControlPersonEmail"
                    );
                    inputField.entityNestedControlEntityControlPersonEmailError =
                      "";
                  }}
                  valueError={
                    inputField.entityNestedControlEntityControlPersonEmailError
                  }
                  setValueError={(event) =>
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityControlPersonEmailError"
                    )
                  }
                  label={"Email"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  id={"entity-nested-control-entity-control-person-email-input"}
                />
              </div>
            </div>
            <div className="col ps-2 pe-0">
              <div className="align-self-end mt-1">
                <PhoneInputField
                  value={
                    inputField.entityNestedControlEntityControlPersonPhoneNumber
                  }
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityControlPersonPhoneNumber"
                    );
                    inputField.entityNestedControlEntityControlPersonPhoneNumberError =
                      "";
                  }}
                  setValueError={(e) => {
                    inputField.entityNestedControlEntityControlPersonPhoneNumberError =
                      e;
                  }}
                  valueError={
                    inputField.entityNestedControlEntityControlPersonPhoneNumberError
                  }
                  label={"Phone Number"}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={
                    "entity-nested-control-entity-control-person-phone-number-input"
                  }
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-2">
              <div className="align-self-end mt-1">
                <SelectInputField
                  value={
                    inputField.entityNestedControlEntityControlPersonIsUSBased
                  }
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityControlPersonIsUSBased"
                    );
                    inputField.entityNestedControlEntityControlPersonIsUSBasedError =
                      "";
                  }}
                  valueError={
                    inputField.entityNestedControlEntityControlPersonIsUSBasedError
                  }
                  setValueError={(e) => {
                    inputField.entityNestedControlEntityControlPersonIsUSBasedError =
                      e;
                  }}
                  selectOptions={yesNoOptions}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={
                    "entity-nested-control-entity-control-person-is-us-based-select"
                  }
                  label={"Is US based?"}
                />
              </div>
            </div>
            <div className="col ps-2 pe-0">
              <div className="align-self-end mt-1">
                <SelectInputField
                  value={
                    inputField.entityNestedControlEntityControlPersonIsUSCitizen
                  }
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityControlPersonIsUSCitizen"
                    );
                    inputField.entityNestedControlEntityControlPersonIsUSCitizenError =
                      "";
                  }}
                  valueError={
                    inputField.entityNestedControlEntityControlPersonIsUSCitizenError
                  }
                  setValueError={(e) => {
                    inputField.entityNestedControlEntityControlPersonIsUSCitizenError =
                      e;
                  }}
                  selectOptions={yesNoOptions}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={
                    "entity-nested-control-entity-control-person-is-us-citizen-select"
                  }
                  label={"Is US citizen?"}
                />
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-0">
              <div className="align-self-end mt-2">
                <SelectInputField
                  value={
                    inputField.entityNestedControlEntityControlPersonPIIAvailable
                  }
                  setValue={(event) => {
                    handleChangeInput(
                      index,
                      event,
                      "entityNestedControlEntityControlPersonPIIAvailable"
                    );
                    inputField.entityNestedControlEntityControlPersonPIIAvailableError =
                      "";
                  }}
                  valueError={
                    inputField.entityNestedControlEntityControlPersonPIIAvailableError
                  }
                  setValueError={(e) => {
                    inputField.entityNestedControlEntityControlPersonPIIAvailableError =
                      e;
                  }}
                  selectOptions={yesNoOptions}
                  required={true}
                  fullWidth={true}
                  size={"small"}
                  disabled={disabled}
                  shrink={true}
                  id={
                    "entity-nested-control-entity-control-person-pii-available-select"
                  }
                  label={"Do you have the PII available for this person?"}
                />
              </div>
            </div>
          </div>
          {inputField.entityNestedControlEntityControlPersonPIIAvailable ===
            "Yes" && (
            <React.Fragment>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-2">
                  <div className="align-self-end mt-1">
                    <TextInputField
                      value={
                        inputField.entityNestedControlEntityControlPersonTaxIDNumber
                      }
                      setValue={(event) => {
                        handleChangeInput(
                          index,
                          event,
                          "entityNestedControlEntityControlPersonTaxIDNumber"
                        );
                        inputField.entityNestedControlEntityControlPersonTaxIDNumberError =
                          "";
                      }}
                      valueError={
                        inputField.entityNestedControlEntityControlPersonTaxIDNumberError
                      }
                      setValueError={(event) =>
                        handleChangeInput(
                          index,
                          event,
                          "entityNestedControlEntityControlPersonTaxIDNumberError"
                        )
                      }
                      label={"SSN"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={disabled}
                      mask={{
                        maskFormatter: formatSSN,
                        maskPattern: "999-99-9999",
                      }}
                      id={
                        "entity-nested-control-entity-control-person-ssn-input"
                      }
                    />
                  </div>
                </div>
                <div className="col ps-2 pe-0">
                  <div className="align-self-end mt-1">
                    <StyledDateInputField
                      value={
                        inputField.entityNestedControlEntityControlPersonDOB
                      }
                      setValue={(event) => {
                        handleChangeInput(
                          index,
                          event,
                          "entityNestedControlEntityControlPersonDOB"
                        );
                        inputField.entityNestedControlEntityControlPersonDOBError =
                          "";
                      }}
                      valueError={
                        inputField.entityNestedControlEntityControlPersonDOBError
                      }
                      setValueError={(e) => {
                        inputField.entityNestedControlEntityControlPersonDOBError =
                          e;
                      }}
                      label={"Date Of Birth"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={disabled}
                      shrink={true}
                      id={
                        "entity-nested-control-entity-control-person-dob-input"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-2">
                  <div className="align-self-end mt-1">
                    <TextInputField
                      value={
                        inputField.entityNestedControlEntityControlPersonAddressLine1
                      }
                      setValue={(event) => {
                        handleChangeInput(
                          index,
                          event,
                          "entityNestedControlEntityControlPersonAddressLine1"
                        );
                        inputField.entityNestedControlEntityControlPersonAddressLine1Error =
                          "";
                      }}
                      valueError={
                        inputField.entityNestedControlEntityControlPersonAddressLine1Error
                      }
                      setValueError={(event) =>
                        handleChangeInput(
                          index,
                          event,
                          "entityNestedControlEntityControlPersonAddressLine1Error"
                        )
                      }
                      label={"Address Line 1"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={disabled}
                      id={
                        "entity-nested-control-entity-control-person-address-line-1-input"
                      }
                    />
                  </div>
                </div>
                <div className="col ps-2 pe-0">
                  <div className="align-self-end mt-1">
                    <TextInputField
                      value={
                        inputField.entityNestedControlEntityControlPersonAddressLine2
                      }
                      setValue={(event) => {
                        handleChangeInput(
                          index,
                          event,
                          "entityNestedControlEntityControlPersonAddressLine2"
                        );
                        inputField.entityNestedControlEntityControlPersonAddressLine2Error =
                          "";
                      }}
                      valueError={
                        inputField.entityNestedControlEntityControlPersonAddressLine2Error
                      }
                      setValueError={(event) =>
                        handleChangeInput(
                          index,
                          event,
                          "entityNestedControlEntityControlPersonAddressLine2Error"
                        )
                      }
                      label={"Address Line 2"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={disabled}
                      id={
                        "entity-nested-control-entity-control-person-address-line-2-input"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-2">
                  <div className="align-self-end mt-1">
                    <TextInputField
                      value={
                        inputField.entityNestedControlEntityControlPersonCity
                      }
                      setValue={(event) => {
                        handleChangeInput(
                          index,
                          event,
                          "entityNestedControlEntityControlPersonCity"
                        );
                        inputField.entityNestedControlEntityControlPersonCityError =
                          "";
                      }}
                      valueError={
                        inputField.entityNestedControlEntityControlPersonCityError
                      }
                      setValueError={(event) =>
                        handleChangeInput(
                          index,
                          event,
                          "entityNestedControlEntityControlPersonCityError"
                        )
                      }
                      label={"City"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={disabled}
                      id={
                        "entity-nested-control-entity-control-person-city-input"
                      }
                    />
                  </div>
                </div>
                <div className="col ps-2 pe-0">
                  <div className="align-self-end mt-1">
                    <SelectInputField
                      value={
                        inputField.entityNestedControlEntityControlPersonState
                      }
                      setValue={(event) => {
                        handleChangeInput(
                          index,
                          event,
                          "entityNestedControlEntityControlPersonState"
                        );
                        inputField.entityNestedControlEntityControlPersonStateError =
                          "";
                      }}
                      valueError={
                        inputField.entityNestedControlEntityControlPersonStateError
                      }
                      setValueError={(e) => {
                        inputField.entityNestedControlEntityControlPersonStateError =
                          e;
                      }}
                      selectOptions={stateList}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={disabled}
                      shrink={true}
                      id={
                        "entity-nested-control-entity-control-person-state-select"
                      }
                      label={"State"}
                    />
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    <TextInputField
                      value={
                        inputField.entityNestedControlEntityControlPersonPostalCode
                      }
                      setValue={(event) => {
                        handleChangeInput(
                          index,
                          event,
                          "entityNestedControlEntityControlPersonPostalCode"
                        );
                        inputField.entityNestedControlEntityControlPersonPostalCodeError =
                          "";
                      }}
                      valueError={
                        inputField.entityNestedControlEntityControlPersonPostalCodeError
                      }
                      setValueError={(event) =>
                        handleChangeInput(
                          index,
                          event,
                          "entityNestedControlEntityControlPersonPostalCodeError"
                        )
                      }
                      label={"Postal Code"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={disabled}
                      id={
                        "entity-nested-control-entity-control-person-postal-code-input"
                      }
                    />
                  </div>
                </div>
              </div>
              {!disabled && (
                <React.Fragment>
                  <div className="row g-0 w-100 mb-2">
                    <div className="col pe-0">
                      <div className="align-self-end mt-1">
                        <h6>
                          Additional Required Trustee Entity Control Person
                          Documentation
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-1">
                    <div className="col pe-0">
                      <div className="align-self-end mt-1">
                        <span className="body-text-sm-semibold">
                          Please upload the following documents for the control
                          person of the trustee entity (Passport, Proof of
                          Address)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-1">
                    <div className="col pe-0">
                      <div className="align-self-end mt-1">
                        {inputField.entityNestedControlEntityControlPersonPassportDocument ===
                        "" ? (
                          <UploadButton
                            text={"Upload Passport"}
                            onChange={handleFileUpload}
                            inputField={
                              "entityNestedControlEntityControlPersonPassportDocument"
                            }
                            label={index + relatedPartyType + "nested"}
                          />
                        ) : (
                          <div className="d-flex">
                            <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                              <span className="body-text-sm-semibold">
                                Passport Uploaded
                              </span>
                            </div>
                            <div className="align-self-center align-items-center pt-auto pb-auto">
                              <UploadButton
                                text={"Change Passport"}
                                onChange={handleFileUpload}
                                inputField={
                                  "entityNestedControlEntityControlPersonPassportDocument"
                                }
                                label={index + relatedPartyType + "nested"}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {inputField.entityNestedControlEntityControlPersonPassportDocumentError !==
                    "" && (
                    <div className="row g-0 w-100 mb-3">
                      <div className="SignupForm-field-error-text mt-1">
                        <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                          {
                            inputField.entityNestedControlEntityControlPersonPassportDocumentError
                          }
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="row g-0 w-100 mb-3">
                    <div className="col pe-0">
                      <div className="align-self-end mt-1">
                        {inputField.entityNestedControlEntityControlPersonProofOfAddress ===
                        "" ? (
                          <UploadButton
                            text={"Upload Proof of Address"}
                            onChange={handleFileUpload}
                            inputField={
                              "entityNestedControlEntityControlPersonProofOfAddress"
                            }
                            label={index + relatedPartyType + "nested"}
                          />
                        ) : (
                          <div className="d-flex">
                            <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                              <span className="body-text-sm-semibold">
                                Proof of Address Uploaded
                              </span>
                            </div>
                            <div className="align-self-center align-items-center pt-auto pb-auto">
                              <UploadButton
                                text={"Update Proof of Address"}
                                onChange={handleFileUpload}
                                inputField={
                                  "entityNestedControlEntityControlPersonProofOfAddress"
                                }
                                label={index + relatedPartyType + "nested"}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {inputField.entityNestedControlEntityControlPersonProofOfAddressError !==
                    "" && (
                    <div className="row g-0 w-100 mb-3">
                      <div className="SignupForm-field-error-text mt-1">
                        <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                          {
                            inputField.entityNestedControlEntityControlPersonProofOfAddressError
                          }
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {!disabled && (
            <React.Fragment>
              <div className="row g-0 w-100 mb-2">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    <h6>Additional Required Trust Documentation</h6>
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100 mb-2">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    <span className="body-text-sm-semibold">
                      Please upload the following documents for this trust
                      (Certificate of formation, Proof of Address):
                    </span>
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100 mb-1">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    {inputField.entityCertificateOfFormation === "" ? (
                      <UploadButton
                        text={"Upload Certificate of Formation"}
                        onChange={handleFileUpload}
                        inputField={"entityCertificateOfFormation"}
                        label={index + relatedPartyType + "somethingEntity2"}
                      />
                    ) : (
                      <div className="d-flex">
                        <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                          <span className="body-text-sm-semibold">
                            Certificate of Formation Uploaded
                          </span>
                        </div>
                        <div className="align-self-center align-items-center pt-auto pb-auto">
                          <UploadButton
                            text={"Change Certificate of Formation"}
                            onChange={handleFileUpload}
                            inputField={"entityCertificateOfFormation"}
                            label={
                              index + relatedPartyType + "somethingEntity2"
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {inputField.entityCertificateOfFormationError !== "" && (
                <div className="row g-0 w-100 mb-3">
                  <div className="SignupForm-field-error-text mt-1">
                    <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                      {inputField.entityCertificateOfFormationError}
                    </span>
                  </div>
                </div>
              )}
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    {inputField.entityProofOfAddress === "" ? (
                      <UploadButton
                        text={"Upload Proof of Address"}
                        onChange={handleFileUpload}
                        inputField={"entityProofOfAddress"}
                        label={index + relatedPartyType + "somethingEntity2"}
                      />
                    ) : (
                      <div className="d-flex">
                        <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                          <span className="body-text-sm-semibold">
                            Proof of Address Uploaded
                          </span>
                        </div>
                        <div className="align-self-center align-items-center pt-auto pb-auto">
                          <UploadButton
                            text={"Change Proof of Address"}
                            onChange={handleFileUpload}
                            inputField={"entityProofOfAddress"}
                            label={
                              index + relatedPartyType + "somethingEntity2"
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {inputField.entityProofOfAddressError !== "" && (
                <div className="row g-0 w-100 mb-3">
                  <div className="SignupForm-field-error-text mt-1">
                    <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                      {inputField.entityProofOfAddressError}
                    </span>
                  </div>
                </div>
              )}

              <div className="row g-0 w-100 mb-2">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    <h6>Additional Required Trustee Entity Documentation</h6>
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100 mb-2">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    <span className="body-text-sm-semibold">
                      "Please upload the following documents for this trustee
                      entity (Certificate of Formation, Proof of Address):"
                    </span>
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100 mb-1">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    {inputField.entityNestedControlEntityCertificateOfFormation ===
                    "" ? (
                      <UploadButton
                        text={"Upload Certificate of Formation"}
                        onChange={handleFileUpload}
                        inputField={
                          "entityNestedControlEntityCertificateOfFormation"
                        }
                        label={index + relatedPartyType + "nestedEntity"}
                      />
                    ) : (
                      <div className="d-flex">
                        <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                          <span className="body-text-sm-semibold">
                            Certificate of Formation Uploaded
                          </span>
                        </div>
                        <div className="align-self-center align-items-center pt-auto pb-auto">
                          <UploadButton
                            text={"Change Certificate of Formation"}
                            onChange={handleFileUpload}
                            inputField={
                              "entityNestedControlEntityCertificateOfFormation"
                            }
                            label={index + relatedPartyType + "nestedEntity"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {inputField.entityNestedControlEntityCertificateOfFormationError !==
                "" && (
                <div className="row g-0 w-100 mb-3">
                  <div className="SignupForm-field-error-text mt-1">
                    <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                      {
                        inputField.entityNestedControlEntityCertificateOfFormationError
                      }
                    </span>
                  </div>
                </div>
              )}
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-0">
                  <div className="align-self-end mt-1">
                    {inputField.entityNestedControlEntityProofOfAddress ===
                    "" ? (
                      <UploadButton
                        text={"Upload Proof of Address"}
                        onChange={handleFileUpload}
                        inputField={"entityNestedControlEntityProofOfAddress"}
                        label={index + relatedPartyType + "nestedEntity"}
                      />
                    ) : (
                      <div className="d-flex">
                        <div className="me-3 align-self-center align-items-center pt-auto pb-auto">
                          <span className="body-text-sm-semibold">
                            Proof of Address Uploaded
                          </span>
                        </div>
                        <div className="align-self-center align-items-center pt-auto pb-auto">
                          <UploadButton
                            text={"Change Proof of Address"}
                            onChange={handleFileUpload}
                            inputField={
                              "entityNestedControlEntityProofOfAddress"
                            }
                            label={index + relatedPartyType + "nestedEntity"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {inputField.entityNestedControlEntityProofOfAddressError !==
                "" && (
                <div className="row g-0 w-100 mb-3">
                  <div className="SignupForm-field-error-text mt-1">
                    <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                      {inputField.entityNestedControlEntityProofOfAddressError}
                    </span>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default IrrevocableTrustRelatedPartyInputInformation;
