import React from "react";
import "./Styles/Styles.scss";
import TextInputField from "../../../../../../Components/InputFields/TextInputField/TextInputField";
import SelectInputField from "../../../../../../Components/InputFields/SelectInputField/SelectInputField";
import { stateList } from "../../../../../../Components/stateList";
import PhoneInputField from "../../../../../../Components/InputFields/PhoneInputField/PhoneInputField";

function EntityInformation({
  accountType,
  entityAddressLine1,
  setEntityAddressLine1,
  entityAddressLine1Error,
  setEntityAddressLine1Error,
  entityAddressLine2,
  setEntityAddressLine2,
  entityAddressLine2Error,
  setEntityAddressLine2Error,
  entityCity,
  setEntityCity,
  entityCityError,
  setEntityCityError,
  entityState,
  setEntityState,
  entityStateError,
  setEntityStateError,
  entityPostalCode,
  setEntityPostalCode,
  entityPostalCodeError,
  setEntityPostalCodeError,
  entityTaxIDNumber,
  setEntityTaxIDNumber,
  entityTaxIDNumberError,
  setEntityTaxIDNumberError,
  entityPhoneNumber,
  setEntityPhoneNumber,
  entityPhoneNumberError,
  setEntityPhoneNumberError,
  entityStateOfIncorporation,
  setEntityStateOfIncorporation,
  entityStateOfIncorporationError,
  setEntityStateOfIncorporationError,
  hasAlternateSignatory,
  setHasAlternateSignatory,
  hasAlternateSignatoryError,
  setHasAlternateSignatoryError,
  alternateSignatoryFullName,
  setAlternateSignatoryFullName,
  alternateSignatoryFullNameError,
  setAlternateSignatoryFullNameError,
  alternateSignatoryEmail,
  setAlternateSignatoryEmail,
  alternateSignatoryEmailError,
  setAlternateSignatoryEmailError,
  alternateSignatoryTitle,
  setAlternateSignatoryTitle,
  alternateSignatoryTitleError,
  setAlternateSignatoryTitleError,
}) {
  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={entityAddressLine1}
            setValue={setEntityAddressLine1}
            valueError={entityAddressLine1Error}
            setValueError={setEntityAddressLine1Error}
            label={"Entity Address Line 1"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-entity-address-line-1-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <TextInputField
            value={entityAddressLine2}
            setValue={setEntityAddressLine2}
            valueError={entityAddressLine2Error}
            setValueError={setEntityAddressLine2Error}
            label={"Entity Address Line 2"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-entity-address-line-2-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={entityCity}
            setValue={setEntityCity}
            valueError={entityCityError}
            setValueError={setEntityCityError}
            label={"Entity City"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-entity-city-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <SelectInputField
            value={entityState}
            setValue={setEntityState}
            valueError={entityStateError}
            setValueError={setEntityStateError}
            selectOptions={stateList}
            label={"State"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-entity-state-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div
          className={
            accountType !== "SM_LLC" && accountType !== "LT"
              ? "col pe-2"
              : "col pe-0"
          }
        >
          <TextInputField
            value={entityPostalCode}
            setValue={setEntityPostalCode}
            valueError={entityPostalCodeError}
            setValueError={setEntityPostalCodeError}
            label={"Entity Postal Code"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-entity-postal-code-input"}
          />
        </div>
        {accountType !== "SM_LLC" && accountType !== "LT" && (
          <div className="col ps-2 pe-0">
            <TextInputField
              value={entityTaxIDNumber}
              setValue={setEntityTaxIDNumber}
              valueError={entityTaxIDNumberError}
              setValueError={setEntityTaxIDNumberError}
              label={"Entity EIN"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"client-entity-tax-id-number-input"}
            />
          </div>
        )}
      </div>
      <div className="row g-0 w-100">
        <div className="col pe-2">
          <SelectInputField
            value={entityStateOfIncorporation}
            setValue={setEntityStateOfIncorporation}
            valueError={entityStateOfIncorporationError}
            setValueError={setEntityStateOfIncorporationError}
            selectOptions={stateList}
            label={"State of Incorporation"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-entity-state-of-incorporation-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <PhoneInputField
            shrink={true}
            label={"Entity Phone Number"}
            size={"small"}
            fullWidth={true}
            required={true}
            value={entityPhoneNumber}
            setValue={setEntityPhoneNumber}
            valueError={entityPhoneNumberError}
            setValueError={setEntityPhoneNumberError}
            disabled={false}
            id={"client-entity-phone-number-input"}
          />
        </div>
      </div>
      {accountType === "TRUST" && (
        <React.Fragment>
          <div className="row g-0 w-100 mb-3 mt-3">
            <div className="col pe-0">
              <span className="body-text-sm-semibold">
                Will there be an alternate signatory for this trust? (If no,
                then the grantor of this trust will be the designated signatory)
              </span>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col-6">
              <label for={"hasAlternateSignatoryId"} className={"radio"}>
                <input
                  type={"radio"}
                  name={"hasAlternateSignatoryRadio"}
                  id={"hasAlternateSignatoryId"}
                  className={"radio__input"}
                  checked={hasAlternateSignatory === "Yes"}
                  onClick={() => {
                    setHasAlternateSignatory("Yes");
                  }}
                />
                <div className="radio__radio"></div>
                <span className="qualification-question-text ms-2">Yes</span>
              </label>
            </div>
            <div className="col-6">
              <label for={"hasNoAlternateSignatoryId"} className={"radio"}>
                <input
                  type={"radio"}
                  name={"hasNoAlternateSignatoryRadio"}
                  id={"hasNoAlternateSignatoryId"}
                  className={"radio__input"}
                  checked={hasAlternateSignatory === "No"}
                  onClick={() => {
                    setHasAlternateSignatory("No");
                  }}
                />
                <div className="radio__radio"></div>
                <span className="qualification-question-text ms-2">No</span>
              </label>
            </div>
          </div>
          {hasAlternateSignatoryError !== "" && (
            <div className="row g-0 w-100 mb-3">
              <div className="SignupForm-field-error-text mt-1">
                <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                  {hasAlternateSignatoryError}
                </span>
              </div>
            </div>
          )}
          {hasAlternateSignatory === "Yes" && (
            <React.Fragment>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-2">
                  <div className="align-items-center mt-1">
                    <TextInputField
                      value={alternateSignatoryFullName}
                      setValue={setAlternateSignatoryFullName}
                      setValueError={setAlternateSignatoryFullNameError}
                      valueError={alternateSignatoryFullNameError}
                      label={"Alternate Signatory Full Name"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={false}
                      shrink={true}
                      id={"alternate-signatory-full-name-input"}
                    />
                  </div>
                </div>
                <div className="col ps-2 pe-0">
                  <div className="align-items-center mt-1">
                    <TextInputField
                      value={alternateSignatoryEmail}
                      setValue={setAlternateSignatoryEmail}
                      setValueError={setAlternateSignatoryEmailError}
                      valueError={alternateSignatoryEmailError}
                      label={"Alternate Signatory Email"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={false}
                      shrink={true}
                      id={"alternate-signatory-email-input"}
                    />
                  </div>
                </div>
              </div>
              <div className="row g-0 w-100">
                <div className="col pe-0">
                  <div className="align-items-center mt-1">
                    <TextInputField
                      value={alternateSignatoryTitle}
                      setValue={setAlternateSignatoryTitle}
                      setValueError={setAlternateSignatoryTitleError}
                      valueError={alternateSignatoryTitleError}
                      label={"Alternate Signatory Title"}
                      required={true}
                      fullWidth={true}
                      size={"small"}
                      disabled={false}
                      shrink={true}
                      id={"alternate-signatory-title-input"}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default EntityInformation;
