import React, { useEffect, useState, useContext } from "react";
import "./Styles/Styles.scss";
import Tabs from "../../../../Components/Tabs/Tabs";
import Tab from "../../../../Components/Tabs/Tab/Tab";
import GeneralInfo from "./GeneralInfo/GeneralInfo";
import Docs from "./Docs/Docs";
import Transactions from "./Transactions/Transactions";
import Investments from "./Investments/Investments";
import CapitalCalls from "./CapitalCalls/CapitalCalls";
import {
  clientDetails,
  clientData,
} from "./../../../../APIs/WealthManagementAPI";
import VerificationDocs from "../VerificationDocs/VerificationDocs";
import { UserContext } from "../../../../Contexts/UserContext";

const DetailedInfo = ({
  id,
  clientName,
  setClientName,
  clientNameError,
  setClientNameError,
  clientEmail,
  setClientEmail,
  clientEmailError,
  setClientEmailError,
  firstName,
  setFirstName,
  firstNameError,
  setFirstNameError,
  lastName,
  setLastName,
  lastNameError,
  setLastNameError,
  email,
  setEmail,
  emailError,
  setEmailError,
  setStatus,
  setInvested,
  setDistributed,
}) => {
  const { user } = useContext(UserContext);
  const [generalInfoSelected, setGeneralInfoSelected] = useState(false);
  const [investmentAccountsSelected, setInvestmentAccountsSelected] =
    useState(false);
  const [investmentsSelected, setInvestmentsSelected] = useState(false);
  const [transactionsSelected, setTransactionsSelected] = useState(false);
  const [capitalCallsSelected, setCapitalCallsSelected] = useState(false);
  const [verificationDocsSelected, setVerificationDocsSelected] =
    useState(false);
  const [docsSelected, setDocsSelected] = useState(false);
  const [graphs, setGraphs] = useState(null);
  const [docs, setDocs] = useState([]);
  const [trans, setTrans] = useState([]);
  const [investments, setInvestments] = useState([]);
  const [investment, setInvestment] = useState(null);
  const [accountType, setAccountType] = useState("");

  useEffect(() => {
    async function getClientDetails() {
      try {
        const response = await clientDetails(id);
        if (response.data) {
          setInvestments(response.data.table_data);
          setGraphs(
            response.data.graph_data.map((e) => ({
              ...e,
              marked_value: e.marked_value.toFixed(2),
            }))
          );
          setInvested(
            response.data.graph_data[response.data.graph_data.length - 1]
              .investment_amount
          );
          setDistributed(
            response.data.graph_data[response.data.graph_data.length - 1]
              .distribution_amount
          );
        }
      } catch (err) {
        {
        }
      }
    }

    const fetchClientData = async () => {
      try {
        const resp = await clientData(id);
        if (resp.data) {
          setDocs(resp.data.docs);
          setTrans(resp.data.transactions);
          const data = resp.data.data[0];
          setClientName(data.client_name);
          setClientEmail(data.client_email);
          setFirstName(data.first_name);
          setLastName(data.last_name);
          setEmail(data.email);
          setStatus(resp.data.status);
          setAccountType(data.accounts[0].account_type);
        }
      } catch (err) {
        {
        }
      }
    };
    getClientDetails();
    fetchClientData();
  }, []);

  useEffect(() => {
    if (generalInfoSelected) {
      setInvestmentAccountsSelected(false);
      setInvestmentsSelected(false);
      setTransactionsSelected(false);
      setDocsSelected(false);
      setCapitalCallsSelected(false);
      setVerificationDocsSelected(false);
    }
  }, [generalInfoSelected]);

  useEffect(() => {
    if (investmentAccountsSelected) {
      setGeneralInfoSelected(false);
      setInvestmentsSelected(false);
      setTransactionsSelected(false);
      setDocsSelected(false);
      setVerificationDocsSelected(false);
    }
  }, [investmentAccountsSelected]);

  useEffect(() => {
    if (investmentsSelected) {
      setGeneralInfoSelected(false);
      setInvestmentAccountsSelected(false);
      setTransactionsSelected(false);
      setDocsSelected(false);
      setCapitalCallsSelected(false);
      setVerificationDocsSelected(false);
    }
  }, [investmentsSelected]);

  useEffect(() => {
    if (transactionsSelected) {
      setGeneralInfoSelected(false);
      setInvestmentAccountsSelected(false);
      setInvestmentsSelected(false);
      setDocsSelected(false);
      setCapitalCallsSelected(false);
      setVerificationDocsSelected(false);
    }
  }, [transactionsSelected]);

  useEffect(() => {
    if (docsSelected) {
      setGeneralInfoSelected(false);
      setInvestmentAccountsSelected(false);
      setInvestmentsSelected(false);
      setTransactionsSelected(false);
      setCapitalCallsSelected(false);
      setVerificationDocsSelected(false);
    }
  }, [docsSelected]);

  useEffect(() => {
    if (capitalCallsSelected) {
      setGeneralInfoSelected(false);
      setInvestmentAccountsSelected(false);
      setInvestmentsSelected(false);
      setTransactionsSelected(false);
      setDocsSelected(false);
      setVerificationDocsSelected(false);
    }
  }, [capitalCallsSelected]);

  useEffect(() => {
    if (verificationDocsSelected) {
      setGeneralInfoSelected(false);
      setInvestmentAccountsSelected(false);
      setInvestmentsSelected(false);
      setTransactionsSelected(false);
      setDocsSelected(false);
      setCapitalCallsSelected(false);
    }
  }, [verificationDocsSelected]);

  const notVendor = user && user.isAuthenticated && user.userRole !== "Vendor";

  return (
    <div className="row g-0 w-100">
      {notVendor ? (
        <Tabs fullWidth>
          <Tab
            key={0}
            title="General Info"
            selected={generalInfoSelected}
            setSelected={setGeneralInfoSelected}
          >
            <GeneralInfo
              accountType={accountType}
              id={id}
              firstName={firstName}
              setFirstName={setFirstName}
              firstNameError={firstNameError}
              setFirstNameError={setFirstNameError}
              lastName={lastName}
              setLastName={setLastName}
              lastNameError={lastNameError}
              setLastNameError={setLastNameError}
              email={email}
              setEmail={setEmail}
              emailError={emailError}
              setEmailError={setEmailError}
            />
          </Tab>
          <Tab
            key={1}
            title="Verification Docs"
            selected={verificationDocsSelected}
            setSelected={setVerificationDocsSelected}
          >
            <VerificationDocs id={id} />
          </Tab>

          <Tab
            key={2}
            title="Investments"
            selected={investmentsSelected}
            setSelected={setInvestmentsSelected}
          >
            <Investments
              data={investments}
              graph={graphs}
              setCapitalCallsSelected={setCapitalCallsSelected}
              setInvestment={setInvestment}
              setInvestmentsSelected={setInvestmentsSelected}
            />
          </Tab>
          <Tab
            key={4}
            title="Docs"
            selected={docsSelected}
            setSelected={setDocsSelected}
          >
            <Docs docs={docs} />
          </Tab>
          <Tab
            key={5}
            title="Capital Calls"
            selected={capitalCallsSelected}
            investment={investment}
            setSelected={setCapitalCallsSelected}
          >
            <CapitalCalls clientToShow={id} />
          </Tab>
        </Tabs>
      ) : (
        <Tabs fullWidth>
          <Tab
            key={0}
            title="General Info"
            selected={generalInfoSelected}
            setSelected={setGeneralInfoSelected}
          >
            <GeneralInfo
              accountType={accountType}
              id={id}
              firstName={firstName}
              setFirstName={setFirstName}
              firstNameError={firstNameError}
              setFirstNameError={setFirstNameError}
              lastName={lastName}
              setLastName={setLastName}
              lastNameError={lastNameError}
              setLastNameError={setLastNameError}
              email={email}
              setEmail={setEmail}
              emailError={emailError}
              setEmailError={setEmailError}
            />
          </Tab>
          <Tab
            key={1}
            title="Investments"
            selected={investmentsSelected}
            setSelected={setInvestmentsSelected}
          >
            <Investments
              data={investments}
              graph={graphs}
              setCapitalCallsSelected={setCapitalCallsSelected}
              setInvestment={setInvestment}
              setInvestmentsSelected={setInvestmentsSelected}
            />
          </Tab>
          <Tab
            key={2}
            title="Docs"
            selected={docsSelected}
            setSelected={setDocsSelected}
          >
            <Docs docs={docs} />
          </Tab>
          <Tab
            key={3}
            title="Capital Calls"
            selected={capitalCallsSelected}
            investment={investment}
            setSelected={setCapitalCallsSelected}
          >
            <CapitalCalls clientToShow={id} />
          </Tab>
        </Tabs>
      )}
    </div>
  );
};
export default DetailedInfo;
