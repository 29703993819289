import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import SearchBar from "../../../Components/SearchBar/SearchBar.js";
import MuiButton from "../../../Components/Buttons/MuiButton/MuiButton.js";
import { SpreadsheetModal } from "./SpreadsheetModal.js";
import "./Styles/Styles.scss";
import MasterOpsClientsTable from "./MasterOpsClientsTable/MasterOpsClientsTable.js";
import AdvisorClientsTable from "./AdvisorClientsTable/AdvisorClientsTable.js";
import { UserContext } from "../../../Contexts/UserContext.js";
import { setRef } from "@mui/material";

const Clients = () => {
  const navigate = useNavigate();
  const [clientSearchQuery, setClientSearchQuery] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [data, setData] = useState([]);
  const [clientData, setClientData] = useState(data);
  const [spreadsheet, setSpreadsheet] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [refreshClients, setRefreshClients] = useState(0);

  const { user } = useContext(UserContext);
  const shareClients = user.firmInfo.shareClients;

  return (
    <div className="container-xxl mt-5 pt-5 pb-5">
      <div className="row g-0 w-100 mt-4 mb-2">
        <h2>Clients</h2>
      </div>
      <div className="row g-0 w-100 mb-4 align-items-center">
        <div className="col-xl-10 col-8">
          <SearchBar
            setValue={setClientSearchQuery}
            placeholder={"Search by full name, etc"}
          />
        </div>
        <div className="col-xl-2 col-4 ms-auto me-0 mt-xl-0 mt-2 justify-self-xl-end justify-content-end">
          { user && user.isAuthenticated && user.userRole !== "Vendor" && <div className="float-xl-end">
            <MuiButton
              buttonText={"Add Client"}
              onClick={() => navigate("/clients/add-firm-client")}
              size={"large"}
            />
          </div> } 
        </div>
        {/* <div className="col-xl-2 col-4 ms-auto me-0 mt-xl-0 mt-2 justify-self-xl-end justify-content-end">
          <div className="float-xl-end">
            <MuiButton
              buttonText={"Upload Spreadsheet"}
              onClick={() => setShowModal(true)}
              size={"large"}
            />
          </div>
        </div> */}
      </div>
      {/* <>
        {
          <SpreadsheetModal
            setShowModal={setShowModal}
            showModal={showModal}
            setRefreshClients={setRefreshClients}
          />
        }
      </> */}
      {user !== null &&
      user !== undefined &&
      user.userRole !== null &&
      user.userRole !== undefined &&
      (user.userRole === "Master" ||
        user.userRole === "Ops" ||
        user.userRole === "Vendor" ||
        (user.userRole === "Relationship Manager" && shareClients)) ? (
        <MasterOpsClientsTable
          clientSearchQuery={clientSearchQuery}
          setClientsLoading={setClientsLoading}
          clientsLoading={clientsLoading}
          refreshClients={refreshClients}
        />
      ) : user !== null &&
        user !== undefined &&
        user.userRole !== null &&
        user.userRole !== undefined &&
        user.userRole === "Advisor" ? (
        <AdvisorClientsTable
          clientSearchQuery={clientSearchQuery}
          setClientsLoading={setClientsLoading}
          clientsLoading={clientsLoading}
          refreshClients={refreshClients}
        />
      ) : null}
    </div>
  );
};

export default Clients;
