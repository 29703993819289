import React, { useEffect, useState } from "react";
import "./Styles/Styles.scss";
import ChooseClientTable from "../../../../../Components/ChooseClientTable/ChooseClientTable";
import { GetTransactions } from "../../../../../Adapters/ClientDetails";
import { DataGridPro } from "@mui/x-data-grid-pro";

function moneyFormatter(cell) {
  if (cell.value === undefined || cell.value === null) {
    return "$ 0";
  }
  var integer = parseInt(cell.value, 10);
  return "$ " + integer.toLocaleString("en-US");
}

const Transactions = ({ trans }) => {
  const { transactions } = GetTransactions("190281908", "ALL");
  // console.log(trans);
  // useEffect(() => {
  //   console.log("Transactions are: " + JSON.stringify(transactions));
  // }, [transactions]);

  const columns = () => [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "firmName",
      text: "Firm Name",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "fundName",
      text: "Fund Name",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "type",
      text: "Type",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "amount",
      text: "Amount",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
      formatter: (cellContent, row) => {
        return (
          <span className="body-text-md-regular">
            ${row.amount.toLocaleString()}
          </span>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "method",
      text: "Method",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "date",
      text: "Date",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
  ];

  const newCols = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "firmName",
      headerName: "Firm Name",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "fundName",
      headerName: "Fund Name",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "type",
      headerName: "Type",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
      valueFormatter: (cellValue) => moneyFormatter(cellValue),
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "method",
      headerName: "Method",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
  ];

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  let transactionTypes = {
    DEPOSIT: "Deposit",
    DISBURSEMENT: "Disbursement",
  };

  let transactionStatuses = {
    PENDING: "Pending",
    IN_PROCESS: "Processing",
    COMPLETED: "Completed",
    FAILED: "Failed",
  };

  let transactionMethods = {
    ACH: "ACH",
    WIRE: "Wire",
    ELECTRONIC_CHEQUE: "Electronic Check",
    MAIL_CHEQUE: "Mail Check",
  };

  return (
    <div className="container ps-3 pe-3">
      <div className="row g-0 w-100 mb-2">
        <h5>Transactions</h5>
      </div>
      <div className="row g-0 w-100">
        {/* <ChooseClientTable
          data={trans.map((e) => ({
            firmName: e.fund.firm.name,
            fundName: e.fund.name,
            amount: e.amount,
            type: transactionTypes[e.type],
            status: transactionStatuses[e.status],
            method: transactionMethods[e.method],
            date: formatDate(e.date),
          }))}
          columns={columns}
        /> */}
        <DataGridPro
          sx={{
            height: "400px",
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              borderRadius: "6px",
            },
          }}
          rows={trans.map((e) => ({
            firmName: e.fund.firm.name,
            fundName: e.fund.name,
            amount: e.amount,
            type: transactionTypes[e.type],
            status: transactionStatuses[e.status],
            method: transactionMethods[e.method],
            date: formatDate(e.date),
          }))}
          columns={newCols}
          loading={false}
          rowHeight={54}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
        />
      </div>
    </div>
  );
};
export default Transactions;
