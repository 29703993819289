import React, { useState, useEffect, useRef } from "react";
import "./Styles/Styles.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import MultiSelectFilter from "../../../Components/MultiSelectFilter/MultiSelectFilter";
import SingleSelectFilter from "../../../Components/SingleSelectFilter/SingleSelectFilter";

function FilterPage() {
  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];
  const [personName, setPersonName] = useState([]);
  const [singleValue, setSingleValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);


  return (
    <div className="container-xxl pt-5 mt-5">
      <div className="row g-0 w-100 mt-5 mb-3">
        <h6>This is the test filter page.</h6>
      </div>
      <div className="row g-0 w-100 mb-3">
        <MultiSelectFilter
          label={"Client Name"}
          options={names}
          selectedValues={personName}
          setSelectedValues={setPersonName}
          loading={loading}
        />
      </div>
      <div className="row g-0 w-100 mb-3">
        <SingleSelectFilter
          label={"Single Name"}
          options={names}
          selectedValue={singleValue}
          setSelectedValue={setSingleValue}
          loading={loading2}
        />
      </div>
    </div>
  );
}
export default FilterPage;
