import React, { useEffect, useState } from "react";
import "./Styles/Styles.scss";
import LeftArrow from "../../../Components/Buttons/LeftArrow/LeftArrow";
import { useNavigate, useParams } from "react-router";
import Header from "./Header/Header";
import DetailedInfo from "./DetailedInfo/DetailedInfo";
import { getClientInfo } from "../../../APIs/ClientDetailsAPI";
import { clientData } from "../../../APIs/WealthManagementAPI";

import Spinner1 from "../../../Components/Spinners/Spinner1";

const ClientDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [clientName, setClientName] = useState("");
  const [clientNameError, setClientNameError] = useState("");

  const [clientEmail, setClientEmail] = useState("");
  const [clientEmailError, setClientEmailError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [status, setStatus] = useState("");
  const [invested, setInvested] = useState(null);
  const [distributed, setDistributed] = useState(null);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getClientInformation() {
      try {
        const response = await getClientInfo(id);
        // const response = await clientData(id);
        if (
          response.data !== null &&
          response.data !== undefined &&
          response.data.length !== 0
        ) {
          setFirstName(response.data[0].firstName);
          setLastName(response.data[0].lastName);
          setEmail(response.data[0].email);
        }
      } catch (err) {
        {
        }
        //console.log("Error is: " + JSON.stringify(err));
      }
    }
    getClientInformation();
  }, []);

  return (
    <div className="container-xxl mt-5 pt-5 pb-5">
      <div className="row g-0 w-100 mt-2 mb-3">
        <div className="d-flex" onClick={() => navigate("/clients")}>
          <LeftArrow onClick={() => navigate("/clients")} />
          <span className="ms-2 body-text-md-medium transparent">
            Back to Clients
          </span>
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <h2>Client Details</h2>
      </div>
      <Header
        clientName={clientName}
        clientEmail={clientEmail}
        firstName={firstName}
        lastName={lastName}
        email={email}
        status={status}
        invested={invested}
        distributed={distributed}
      />
      <DetailedInfo
        id={id}
        clientName={clientName}
        setClientName={setClientName}
        clientNameError={clientNameError}
        setClientNameError={setClientNameError}
        clientEmail={clientEmail}
        setClientEmail={setClientEmail}
        clientEmailError={clientEmailError}
        firstName={firstName}
        setFirstName={setFirstName}
        firstNameError={firstNameError}
        setFirstNameError={setFirstNameError}
        lastName={lastName}
        setLastName={setLastName}
        lastNameError={lastNameError}
        setLastNameError={setLastNameError}
        email={email}
        setEmail={setEmail}
        emailError={emailError}
        setEmailError={setEmailError}
        setInvested={setInvested}
        setDistributed={setDistributed}
        setStatus={setStatus}
      />
    </div>
  );
};
export default ClientDetails;
