import React, { useEffect } from "react";
import "./Styles/Styles.scss";
import { useNavigate, useLocation } from "react-router-dom";

const NavLinks = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const fundDetailsPathRegex = new RegExp("^(/fund/([^/])+$)");
  const investFundPathRegex = new RegExp(
    "^(/fund/([^/])+(/confirm-investment)$)"
  );

  const FundDetailsNavLinks = () => {
    return (
      <div className="col-sm-4">
        <span
          className="body-text-xs-medium"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/invest")}
        >
          Invest
        </span>
        <img
          className="ms-1"
          src="https://d1swyfveypj49y.cloudfront.net/SideArrow.svg"
          alt="sidearrow"
        />
        <span
          className="body-text-xs-medium-light ps-1"
          style={{ cursor: "pointer" }}
        >
          Fund
        </span>
      </div>
    );
  };

  const InvestFundNavLinks = () => {
    return (
      <div className="col-sm-4">
        <span
          className="body-text-xs-medium"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/invest")}
        >
          Invest
        </span>
        <img
          className="ms-1"
          src="https://d1swyfveypj49y.cloudfront.net/SideArrow.svg"
          alt="sidearrow"
        />
        <span
          className="body-text-xs-medium ps-1"
          style={{ cursor: "pointer" }}
          onClick={() => {
            var curPath = location.pathname;
            curPath = curPath.substring(0, curPath.length - 19);
            navigate(curPath);
          }}
        >
          Fund
        </span>
        <img
          className="ms-1"
          src="https://d1swyfveypj49y.cloudfront.net/SideArrow.svg"
          alt="sidearrow"
        />
        <span
          className="body-text-xs-medium-light ps-1"
          style={{ cursor: "pointer" }}
        >
          Investment
        </span>
      </div>
    );
  };

  return (
    <div className="row g-0 w-100">
      {fundDetailsPathRegex.test(location.pathname) ? (
        <FundDetailsNavLinks />
      ) : investFundPathRegex.test(location.pathname) ? (
        <InvestFundNavLinks />
      ) : null}
    </div>
  );
};
export default NavLinks;
