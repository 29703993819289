import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../Contexts/UserContext";

// import { useLocation } from "react-router-dom";
//import Header from "../../../Components/GlobalComponents/Header.js";
//import {getAccountSummary } from "./../../../Api/ProfileApi.js";
//import ProfileHeader from "./ProfileHeader.js";
//import InvestorInformation from "./InvestorInformation.js";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import VerticalTabs from "./../../Components/VerticalTab/VerticalTabs.js";
import VerticalTab from "./../../Components/VerticalTab/VerticalTab.js";
import AdvisorByAdvisor from "./AdvisorByAdvisor.js";
import FirmByFirm from "./FirmByFirm.js";
import AggViews from "./AggViews.js";

function AggViewsMain() {
  const [active, setActive] = useState("");
  const { user } = useContext(UserContext);

  const isAdvisor = !(user.userRole === "Master" || user.userRole === "Ops");
  const isMasterOps = user.userRole === "Master" || user.userRole === "Ops";
  const shareClients = user.firmInfo.shareClients;

  return (
    <React.Fragment>
      <div className="container-xxl container-fluid w-sm-75 w-100 justify-content-center pb-3">
        <div className="row g-0 w-100 mb-2">
          {isAdvisor ? <h5>Firm Performance</h5> : <h5>Performance</h5>}
        </div>
        <div className="row g-0 w-100 justify-content-center mb-4">
          <div className="contain-content ps-2 pt-2">
            <AggViews isAdvisor={isAdvisor} />
          </div>
        </div>
        {isMasterOps && !shareClients && (
          <>
            {" "}
            <div className="row g-0 w-100 mb-2">
              <h5>Advisor Investments</h5>
            </div>
            <div className="row g-0 w-100 mb-4">
              <div className="contain-content p-3">
                <AdvisorByAdvisor />
              </div>
            </div>{" "}
          </>
        )}
        <div className="row g-0 w-100 mb-2">
          <h5>Fund Investments</h5>
        </div>
        <div className="row g-0 w-100 mb-4">
          <div className="contain-content p-3">
            <FirmByFirm isAdvisor={isAdvisor} />
          </div>
        </div>
        {/* <div className="row g-0 w-100 justify-content-center mb-5">
          <div class="contain-content p-2">
            <AggViews />
          </div>{" "}
          {
            <div className=" col-12 mt-3 mb-2">
              <h5>Advisor Investments</h5>
            </div>
          }
          <div class="contain-content p-5">
            <AdvisorByAdvisor />
          </div>{" "}
          {
            <div className=" col-12 mt-3 mb-2">
              <h5>Fund Investments</h5>
            </div>
          }
          <div class="contain-content p-5">
            <FirmByFirm />
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
}

export default AggViewsMain;
