import React from "react";
import "./Styles/Styles.scss";
import TextInputField from "../../../../../Components/InputFields/TextInputField/TextInputField";
import PhoneInputField from "../../../../../Components/InputFields/PhoneInputField/PhoneInputField";
import SelectInputField from "../../../../../Components/InputFields/SelectInputField/SelectInputField";
import { formatSSN } from "../../../../../utilities/functions/functions";
import StyledDateInputField from "../../../../../Components/StyledDateInput/StyledDateInput";
import { stateList } from "../../../../../Components/stateList";

function ClientInformation({
  percentOwned,
  setPercentOwned,
  percentOwnedError,
  setPercentOwnedError,
  accountType,
  setAccountType,
  clientFirstName,
  setClientFirstName,
  clientFirstNameError,
  setClientFirstNameError,
  clientLastName,
  setClientLastName,
  clientLastNameError,
  setClientLastNameError,
  clientEmail,
  setClientEmail,
  clientEmailError,
  setClientEmailError,
  clientPhoneNumber,
  setClientPhoneNumber,
  clientPhoneNumberError,
  setClientPhoneNumberError,
  clientAddressLine1,
  setClientAddressLine1,
  clientAddressLine1Error,
  setClientAddressLine1Error,
  clientAddressLine2,
  setClientAddressLine2,
  clientAddressLine2Error,
  setClientAddressLine2Error,
  clientCity,
  setClientCity,
  clientCityError,
  setClientCityError,
  clientState,
  setClientState,
  clientStateError,
  setClientStateError,
  clientPostalCode,
  setClientPostalCode,
  clientPostalCodeError,
  setClientPostalCodeError,
  clientTaxIdNumber,
  setClientTaxIdNumber,
  clientTaxIdNumberError,
  setClientTaxIdNumberError,
  clientDOB,
  setClientDOB,
  clientDOBError,
  setClientDOBError,
  clientIsUSCitizen,
  setClientIsUSCitizen,
  clientIsUSCitizenError,
  setClientIsUSCitizenError,
  citizenship,
  setCitizenship,
  citizenshipError,
  setCitizenshipError,
  country,
  setCountry,
  countryError,
  setCountryError,
}) {
  const countryList = [
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Åland Islands", label: "Åland Islands" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "American Samoa", label: "American Samoa" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Antarctica", label: "Antarctica" },
    { value: "Antigua And Barbuda", label: "Antigua And Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Bhutan", label: "Bhutan" },
    {
      value: "Bolivia, Plurinational State Of",
      label: "Bolivia, Plurinational State Of",
    },
    {
      value: "Bonaire, Sint Eustatius And Saba",
      label: "Bonaire, Sint Eustatius And Saba",
    },
    { value: "Bosnia And Herzegovina", label: "Bosnia And Herzegovina" },
    { value: "Botswana", label: "Botswana" },
    { value: "Bouvet Island", label: "Bouvet Island" },
    { value: "Brazil", label: "Brazil" },
    {
      value: "British Indian Ocean Territory",
      label: "British Indian Ocean Territory",
    },
    { value: "Brunei Darussalam", label: "Brunei Darussalam" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Cape Verde", label: "Cape Verde" },
    { value: "Cayman Islands", label: "Cayman Islands" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "Christmas Island", label: "Christmas Island" },
    { value: "Cocos (keeling) Islands", label: "Cocos (keeling) Islands" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo", label: "Congo" },
    { value: "Cook Islands", label: "Cook Islands" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Côte D'ivoire", label: "Côte D'ivoire" },
    { value: "Croatia", label: "Croatia" },
    { value: "Curaçao", label: "Curaçao" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Ethiopia", label: "Ethiopia" },
    {
      value: "Falkland Islands (malvinas)",
      label: "Falkland Islands (malvinas)",
    },
    { value: "Faroe Islands", label: "Faroe Islands" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "French Guiana", label: "French Guiana" },
    { value: "French Polynesia", label: "French Polynesia" },
    {
      value: "French Southern Territories",
      label: "French Southern Territories",
    },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Gibraltar", label: "Gibraltar" },
    { value: "Greece", label: "Greece" },
    { value: "Greenland", label: "Greenland" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guadeloupe", label: "Guadeloupe" },
    { value: "Guam", label: "Guam" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guernsey", label: "Guernsey" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-bissau", label: "Guinea-bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    {
      value: "Heard Island And Mcdonald Islands",
      label: "Heard Island And Mcdonald Islands",
    },
    {
      value: "Holy See (vatican City State)",
      label: "Holy See (vatican City State)",
    },
    { value: "Honduras", label: "Honduras" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Ireland", label: "Ireland" },
    { value: "Isle Of Man", label: "Isle Of Man" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jersey", label: "Jersey" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Korea, Republic Of", label: "Korea, Republic Of" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    {
      value: "Lao People's Democratic Republic",
      label: "Lao People's Democratic Republic",
    },
    { value: "Latvia", label: "Latvia" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Libya", label: "Libya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Macao", label: "Macao" },
    {
      value: "Macedonia, TheFormer Yugoslav Republic Of",
      label: "Macedonia, TheFormer Yugoslav Republic Of",
    },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Martinique", label: "Martinique" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mayotte", label: "Mayotte" },
    { value: "Mexico", label: "Mexico" },
    {
      value: "Micronesia, Federated States Of",
      label: "Micronesia, Federated States Of",
    },
    { value: "Moldova, Republic Of", label: "Moldova, Republic Of" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "New Caledonia", label: "New Caledonia" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Niger", label: "Niger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Niue", label: "Niue" },
    { value: "Norfolk Island", label: "Norfolk Island" },
    { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    { value: "Palestine, State Of", label: "Palestine, State Of" },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Pitcairn", label: "Pitcairn" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Puerto Rico", label: "Puerto Rico" },
    { value: "Qatar", label: "Qatar" },
    { value: "Réunion", label: "Réunion" },
    { value: "Romania", label: "Romania" },
    { value: "Rwanda", label: "Rwanda" },
    { value: "Saint Barthélemy", label: "Saint Barthélemy" },
    {
      value: "Saint Helena, Ascension And Tristan Da Cunha",
      label: "Saint Helena, Ascension And Tristan Da Cunha",
    },
    { value: "Saint Kitts And Nevis", label: "Saint Kitts And Nevis" },
    { value: "Saint Lucia", label: "Saint Lucia" },
    {
      value: "Saint Martin (french Part)",
      label: "Saint Martin (french Part)",
    },
    { value: "Saint Pierre And Miquelon", label: "Saint Pierre And Miquelon" },
    {
      value: "Saint Vincent And The Grenadines",
      label: "Saint Vincent And The Grenadines",
    },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome And Principe", label: "Sao Tome And Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia", label: "Serbia" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Sint Maarten (dutch Part)", label: "Sint Maarten (dutch Part)" },
    { value: "Slovakia", label: "Slovakia" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "South Africa", label: "South Africa" },
    {
      value: "South Georgia And The South Sandwich Islands",
      label: "South Georgia And The South Sandwich Islands",
    },
    { value: "Spain", label: "Spain" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Svalbard And Jan Mayen", label: "Svalbard And Jan Mayen" },
    { value: "Swaziland", label: "Swaziland" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Taiwan, Province Of China", label: "Taiwan, Province Of China" },
    { value: "Tajikistan", label: "Tajikistan" },
    {
      value: "Tanzania, United Republic Of",
      label: "Tanzania, United Republic Of",
    },
    { value: "Thailand", label: "Thailand" },
    { value: "Timor-leste", label: "Timor-leste" },
    { value: "Togo", label: "Togo" },
    { value: "Tokelau", label: "Tokelau" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad And Tobago", label: "Trinidad And Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Turks And Caicos Islands", label: "Turks And Caicos Islands" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
    {
      value: "United States Minor Outlying Islands",
      label: "United States Minor Outlying Islands",
    },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Viet Nam", label: "Viet Nam" },
    { value: "Virgin Islands, British", label: "Virgin Islands, British" },
    { value: "Virgin Islands, U.s.", label: "Virgin Islands, U.s." },
    { value: "Wallis And Futuna", label: "Wallis And Futuna" },
    { value: "Western Sahara", label: "Western Sahara" },
    { value: "Zambia", label: "Zambia" },
  ];

  const reducedCountryList = [
    { value: "United States", label: "United States" },
  ];

  const booleanList = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  return (
    <React.Fragment>
      <div className="row g-0 w-100 align-items-center mb-2">
        <h6 className="mt-auto mb-auto">Client Information</h6>
      </div>
      <div className="row g-0 w-100 align-items-center mb-4">
        <span className="body-text-xs-medium">
          *Enter the Grantor Information if you're adding a trust account
        </span>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={clientFirstName}
            setValue={setClientFirstName}
            valueError={clientFirstNameError}
            setValueError={setClientFirstNameError}
            label={"First Name"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-first-name-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <TextInputField
            value={clientLastName}
            setValue={setClientLastName}
            valueError={clientLastNameError}
            setValueError={setClientLastNameError}
            label={"Last Name"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-last-name-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={clientEmail}
            setValue={setClientEmail}
            valueError={clientEmailError}
            setValueError={setClientEmailError}
            label={"Email"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-email-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <PhoneInputField
            shrink={true}
            label={"Phone Number"}
            size={"small"}
            fullWidth={true}
            required={true}
            value={clientPhoneNumber}
            setValue={setClientPhoneNumber}
            valueError={clientPhoneNumberError}
            setValueError={setClientPhoneNumberError}
            disabled={false}
            id={"client-phone-number-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={clientAddressLine1}
            setValue={setClientAddressLine1}
            valueError={clientAddressLine1Error}
            setValueError={setClientAddressLine1Error}
            label={"Address Line 1"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-address-line-1-input"}
          />
        </div>
        <div className="col ps-2 pe-0">
          <TextInputField
            value={clientAddressLine2}
            setValue={setClientAddressLine2}
            valueError={clientAddressLine2Error}
            setValueError={setClientAddressLine2Error}
            label={"Address Line 2"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-address-line-2-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          <TextInputField
            value={clientCity}
            setValue={setClientCity}
            valueError={clientCityError}
            setValueError={setClientCityError}
            label={"City"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-city-input"}
          />
        </div>
        {country === "United States" && (
          <div className="col ps-2 pe-0">
            <SelectInputField
              value={clientState}
              setValue={setClientState}
              valueError={clientStateError}
              setValueError={setClientStateError}
              selectOptions={stateList}
              label={"State"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"client-state-input"}
            />
          </div>
        )}
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-0">
          <TextInputField
            value={clientPostalCode}
            setValue={setClientPostalCode}
            valueError={clientPostalCodeError}
            setValueError={setClientPostalCodeError}
            label={"Postal Code"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-postal-code-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-3">
        <div className="col pe-0">
          <SelectInputField
            value={country}
            setValue={setCountry}
            valueError={countryError}
            setValueError={setCountryError}
            selectOptions={reducedCountryList}
            label={"Country"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-citizenship-input"}
          />
        </div>
      </div>

      <div className="row g-0 w-100 mb-3">
        <div className="col pe-2">
          {country === "United States" ? (
            <TextInputField
              value={clientTaxIdNumber}
              setValue={setClientTaxIdNumber}
              valueError={clientTaxIdNumberError}
              setValueError={setClientTaxIdNumberError}
              label={"SSN"}
              required={true}
              fullWidth={true}
              size={"small"}
              mask={{
                maskFormatter: formatSSN,
                maskPattern: "999-99-9999",
              }}
              id={"client-ssn-input"}
            />
          ) : (
            <React.Fragment>
              <TextInputField
                value={clientTaxIdNumber}
                setValue={setClientTaxIdNumber}
                valueError={clientTaxIdNumberError}
                setValueError={setClientTaxIdNumberError}
                label={"Passport Number"}
                required={true}
                fullWidth={true}
                size={"small"}
                disabled={false}
                shrink={true}
                id={"account-name-input"}
              />
              {clientTaxIdNumberError &&
                clientTaxIdNumberError !== "This field is required." && (
                  <div className="SignupForm-field-error-text mt-1">
                    <span className="">{clientTaxIdNumberError}</span>
                  </div>
                )}
            </React.Fragment>
          )}
        </div>
        <div className="col pe-0">
          <StyledDateInputField
            value={clientDOB}
            setValue={setClientDOB}
            valueError={clientDOBError}
            setValueError={setClientDOBError}
            label={"Date Of Birth"}
            required={true}
            fullWidth={true}
            size={"small"}
            shrink={true}
            id={"client-dob-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100">
        <div className="col pe-0">
          <SelectInputField
            value={clientIsUSCitizen}
            setValue={setClientIsUSCitizen}
            valueError={clientIsUSCitizenError}
            setValueError={setClientIsUSCitizenError}
            selectOptions={booleanList}
            label={"Is U.S. Citizen"}
            required={true}
            fullWidth={true}
            size={"small"}
            id={"client-citizenship-input"}
          />
        </div>

        {(accountType === "LT" || accountType === "TRUST") && (
          <div className="col ps-2 pe-0">
            <TextInputField
              value={percentOwned}
              setValue={setPercentOwned}
              valueError={percentOwnedError}
              setValueError={setPercentOwnedError}
              mask={{
                maskFormatter: (e) => (parseInt(e) <= 100 ? e : e.slice(0, 2)),
                maskPattern: "999",
              }}
              label={"Percent Ownership"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"client-entity-tax-id-number-input"}
            />
          </div>
        )}
      </div>
      {clientIsUSCitizen === "No" && (
        <div className="row g-0 w-100 mt-3">
          <div className="col pe-0">
            <SelectInputField
              value={citizenship}
              setValue={setCitizenship}
              valueError={citizenshipError}
              setValueError={setCitizenshipError}
              selectOptions={countryList}
              label={"Citizenship"}
              required={true}
              fullWidth={true}
              size={"small"}
              id={"client-citizenship-input"}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
export default ClientInformation;
