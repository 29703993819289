import React, { useState, useEffect, useContext } from "react";
import TextInputField from "../../../../../Components/InputFields/TextInputField/TextInputField";
import SelectInputField from "../../../../../Components/InputFields/SelectInputField/SelectInputField";
import StyledDateInputField from "../../../../../Components/StyledDateInput/StyledDateInput";
import UploadButton from "../../../../../Components/Buttons/UploadButton/UploadButton";
import { countryList } from "../../../../../utilities/lists/countryList";
import { stateList } from "../../../../../Components/stateList";
import { formatSSN } from "../../../../../utilities/functions/functions";
import { clientData } from "../../../../../APIs/WealthManagementAPI.js";
import PhoneInputField from "../../../../../Components/InputFields/PhoneInputField/PhoneInputField.js";
import "./Styles/Styles.scss";
import { DataArray } from "@mui/icons-material";
import InfiniteTabInput from "../../../../../Components/InfiniteInputComponent/InfiniteTabInput.js";
import InfiniteInputComponent from "../../../../../Components/InfiniteInputComponent/InfiniteInputComponent.js";
import { UserContext } from "../../../../../Contexts/UserContext.js";

const GeneralInfo = ({
  id,
  accountType,
  firstName,
  setFirstName,
  firstNameError,
  setFirstNameError,
  lastName,
  setLastName,
  lastNameError,
  setLastNameError,
  email,
  setEmail,
  emailError,
  setEmailError,
}) => {
  const { user } = useContext(UserContext);
  // const [firstName, setFirstName] = useState("Don");
  // const [firstNameError, setFirstNameError] = useState("");

  // const [lastName, setLastName] = useState("Stratton");
  // const [lastNameError, setLastNameError] = useState("");

  // const [email, setEmail] = useState("don@don.com");
  // const [emailError, setEmailError] = useState("");
  const [trusteeActiveTab, setTrusteeActiveTab] = useState("Trustee #1");
  const [beneficialOwnerActiveTab, setBeneficialOwnerActiveTab] = useState(
    "Beneficial Owner #1"
  );
  const [phoneNumber, setPhoneNumber] = useState("+1 (901) 291-2908");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [domicileCountry, setDomicileCountry] = useState("United States");
  const [domicileCountryError, setDomicileCountryError] = useState("");

  const [dateAdded, setDateAdded] = useState("12-19-2021");
  const [dateAddedError, setDateAddedError] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine1Error, setAddressLine1Error] = useState("");

  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine2Error, setAddressLine2Error] = useState("");

  const [city, setCity] = useState("New York");
  const [cityError, setCityError] = useState("");

  const [state, setState] = useState("New York");
  const [stateError, setStateError] = useState("");

  const [country, setCountry] = useState("United States");
  const [countryError, setCountryError] = useState("");

  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");

  const [dob, setDob] = useState("");
  const [dobError, setDobError] = useState("");

  const [idType, setIdType] = useState("");
  const [idNum, setIdNum] = useState("");
  const [idNumError, setIdNumError] = useState("");

  const [poaStatus, setPoaStatus] = useState("Not submitted");
  const [circleDiv, setCircleDiv] = useState("");

  const [entityLegalName, setEntityLegalName] = useState("");
  const [entityLegalNameError, setEntityLegalNameError] = useState("");

  const [entityType, setEntityType] = useState("");
  const [entityTypeError, setEntityTypeError] = useState("");

  const [entityStateOfIncorporation, setEntityStateOfIncorporation] =
    useState("");
  const [entityStateOfIncorporationError, setEntityStateOfIncorporationError] =
    useState("");

  const [entityAddressLine1, setEntityAddressLine1] = useState("");
  const [entityAddressLine1Error, setEntityAddressLine1Error] = useState("");

  const [entityAddressLine2, setEntityAddressLine2] = useState("");
  const [entityAddressLine2Error, setEntityAddressLine2Error] = useState("");

  const [entityCity, setEntityCity] = useState("");
  const [entityCityError, setEntityCityError] = useState("");

  const [entityState, setEntityState] = useState("");
  const [entityStateError, setEntityStateError] = useState("");

  const [entityCountry, setEntityCountry] = useState("");
  const [entityCountryError, setEntityCountryError] = useState("");

  const [entityPostalCode, setEntityPostalCode] = useState("");
  const [entityPostalCodeError, setEntityPostalCodeError] = useState("");

  const [entityMailingAddressLine1, setEntityMailingAddressLine1] =
    useState("");
  const [entityMailingAddressLine1Error, setEntityMailingAddressLine1Error] =
    useState("");

  const [entityMailingAddressLine2, setEntityMailingAddressLine2] =
    useState("");
  const [entityMailingAddressLine2Error, setEntityMailingAddressLine2Error] =
    useState("");

  const [entityMailingCity, setEntityMailingCity] = useState("");
  const [entityMailingCityError, setEntityMailingCityError] = useState("");

  const [entityMailingState, setEntityMailingState] = useState("");
  const [entityMailingStateError, setEntityMailingStateError] = useState("");

  const [entityMailingCountry, setEntityMailingCountry] = useState("");
  const [entityMailingCountryError, setEntityMailingCountryError] =
    useState("");

  const [entityMailingPostalCode, setEntityMailingPostalCode] = useState("");
  const [entityMailingPostalCodeError, setEntityMailingPostalCodeError] =
    useState("");

  const [entityPhoneNumber, setEntityPhoneNumber] = useState("");
  const [entityPhoneNumberError, setEntityPhoneNumberError] = useState("");

  const [entityTaxIDNumber, setEntityTaxIDNumber] = useState("");
  const [entityTaxIDNumberError, setEntityTaxIDNumberError] = useState("");

  const [authorizedSignatoryName, setAuthorizedSignatoryName] = useState("");
  const [authorizedSignatoryNameError, setAuthorizedSignatoryNameError] =
    useState("");

  const [authorizedSignatoryEmail, setAuthorizedSignatoryEmail] = useState("");
  const [authorizedSignatoryEmailError, setAuthorizedSignatoryEmailError] =
    useState("");

  const [authorizedSignatoryTitle, setAuthorizedSignatoryTitle] = useState("");
  const [authorizedSignatoryTitleError, setAuthorizedSignatoryTitleError] =
    useState("");

  const [trusteeInformation, setTrusteeInformation] = useState([]);
  const [beneficialOwnerInformation, setBeneficialOwnerInformation] = useState(
    []
  );

  useEffect(() => {
    if (poaStatus === "Not submitted") {
      setCircleDiv("blue-circle");
    } else if (poaStatus === "Pending Verification") {
      setCircleDiv("yellow-circle");
    } else if (poaStatus === "Verified") {
      setCircleDiv("green-circle");
    } else if (poaStatus === "Failed verification") {
      setCircleDiv("red-circle");
    }
  }, [poaStatus]);

  useEffect(() => {
    if (domicileCountry !== "United States") {
      setIdType("PASS");
    } else {
      setIdType("SSN");
    }
  }, [domicileCountry]);

  const formatDate = (inputDate) => {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.

      var month = date.getMonth() + 1;

      if (month < 10) {
        month = "0" + month;
      }

      var dateDay = date.getDate();

      if (dateDay < 10) {
        dateDay = "0" + dateDay;
      }
      return month + "-" + dateDay + "-" + date.getFullYear();
    }
  };

  const fetchClientData = async () => {
    const resp = await clientData(id);

    if (resp.data) {
      const data = resp.data.data[0];

      setFirstName(data.first_name);
      setLastName(data.last_name);
      setPhoneNumber(data.phone_number);

      if (data.investor_addresses) {
      setAddressLine1(data.investor_addresses[0].address_line_1);
      setAddressLine2(data.investor_addresses[0].address_line_2);

      setCity(data.investor_addresses[0].city);
      setCountry(data.investor_addresses[0].country);

      setPostalCode(data.investor_addresses[0].postal_code);

      setState(data.investor_addresses[0].state); }

      var str = data.created_at;
      var substring = str.substring(0, 10);
      var formattedDate = formatDate(substring);

      setDateAdded(formattedDate);

      if (data.investor_pii) {
      setIdNum(data.investor_pii[0].id_num);

      setDob(formatDate(data.investor_pii[0].date_of_birth)); }

      if (data.accounts[0].account_type !== "IND") {
        setEntityLegalName(data.accounts[0].entities[0].legal_name);
        setEntityType(data.accounts[0].entities[0].type);
        setEntityStateOfIncorporation(
          data.accounts[0].entities[0].state_of_incorporation
        );
        
        let entityAddresses = data.accounts[0].entities[0].addresses;

        for (let i = 0; i < entityAddresses.length; i++) {
          if (
            entityAddresses[i].address_type === "R" ||
            entityAddresses[i].address_type === "RM"
          ) {
            setEntityAddressLine1(entityAddresses[i].address_line_1);
            setEntityAddressLine2(entityAddresses[i].address_line_2);
            setEntityCity(entityAddresses[i].city);
            setEntityCountry(entityAddresses[i].country);
            setEntityPostalCode(entityAddresses[i].postal_code);
            setEntityState(entityAddresses[i].state);
          } else if (entityAddresses[i].address_type === "M") {
            setEntityMailingAddressLine1(entityAddresses[i].address_line_1);
            setEntityMailingAddressLine2(entityAddresses[i].address_line_2);
            setEntityMailingCity(entityAddresses[i].city);
            setEntityMailingCountry(entityAddresses[i].country);
            setEntityMailingPostalCode(entityAddresses[i].postal_code);
            setEntityMailingState(entityAddresses[i].state);
          }
        }

        if (
          data.accounts[0].entities[0].type !== "LT" &&
          data.accounts[0].entities[0].type !== "SM_LLC" &&
          data.accounts[0].entities[0].tax_info &&
          data.accounts[0].entities[0].tax_info.length !== 0
        ) {
          setEntityTaxIDNumber(
            data.accounts[0].entities[0]?.tax_info[0].id_number
          );
        }

        setEntityPhoneNumber(data.accounts[0].entities[0].business_phone);

        if (data.accounts[0].authorized_signatory.id !== "") {
          setAuthorizedSignatoryName(
            data.accounts[0].authorized_signatory.name
          );
          setAuthorizedSignatoryEmail(
            data.accounts[0].authorized_signatory.email
          );
          setAuthorizedSignatoryTitle(
            data.accounts[0].authorized_signatory.title
          );
        }

        let trusteeArray = [];
        let beneficialOwnerArray = [];

        let relatedPartyPeople =
          data.accounts[0].entities[0]?.related_parties_people;
        let relatedPartyEntities =
          data.accounts[0].entities[0]?.related_parties_entities;

        for (let i = 0; i < relatedPartyEntities.length; i++) {
          if (relatedPartyEntities[i].role === "TST") {
            let entity = {
              relatedPartyType: relatedPartyEntities[i].related_party_type,
              relatedPartyTypeError: "",
              entityPercentOwned: relatedPartyEntities[i].entity_percent_owned,
              entityPercentOwnedError: "",
              entityName: relatedPartyEntities[i].entity_name,
              entityNameError: "",
              entityAddressLine1: relatedPartyEntities[i].entity_address_line_1,
              entityAddressLine1Error: "",
              entityAddressLine2: relatedPartyEntities[i].entity_address_line_2,
              entityAddressLine2Error: "",
              entityCity: relatedPartyEntities[i].entity_city,
              entityCityError: "",
              entityState: relatedPartyEntities[i].entity_state,
              entityStateError: "",
              entityPostalCode: relatedPartyEntities[i].entity_postal_code,
              entityPostalCodeError: "",
              entityTaxIDNumber: relatedPartyEntities[i].entity_tax_id_number,
              entityTaxIDNumberError: "",
              entityPhoneNumber: relatedPartyEntities[i].entity_phone_number,
              entityPhoneNumberError: "",
              entityStateOfIncorporation:
                relatedPartyEntities[i].entity_state_of_incorporation,
              entityStateOfIncorporationError: "",
              entityMailingAddressDifferent:
                relatedPartyEntities[i].address_type === "R" ? "Yes" : "No",
              entityMailingAddressDifferentError: "",
              entityMailingAddressLine1:
                relatedPartyEntities[i].entity_mailing_address_line_1,
              entityMailingAddressLine1Error: "",
              entityMailingAddressLine2:
                relatedPartyEntities[i].entity_mailing_address_line_2,
              entityMailingAddressLine2Error: "",
              entityMailingCity: relatedPartyEntities[i].entity_mailing_city,
              entityMailingCityError: "",
              entityMailingState: relatedPartyEntities[i].entity_mailing_state,
              entityMailingStateError: "",
              entityMailingPostalCode:
                relatedPartyEntities[i].entity_mailing_postal_code,
              entityMailingPostalCodeError: "",
              entityType: relatedPartyEntities[i].entity_type,
              entityTypeError: "",
              entityControlPersonFirstName:
                relatedPartyEntities[i].entity_control_person_first_name,
              entityControlPersonFirstNameError: "",
              entityControlPersonLastName:
                relatedPartyEntities[i].entity_control_person_last_name,
              entityControlPersonLastNameError: "",
              entityControlPersonEmail:
                relatedPartyEntities[i].entity_control_person_email,
              entityControlPersonEmailError: "",
              entityControlPersonIsUSBased:
                relatedPartyEntities[i].entity_control_person_is_us_based ===
                  true ||
                  relatedPartyEntities[i].entity_control_person_is_us_based ===
                  "Yes"
                  ? "Yes"
                  : "No",
              entityControlPersonIsUSBasedError: "",
              entityControlPersonIsUSCitizen:
                relatedPartyEntities[i].entity_control_person_is_us_citizen ===
                  true ||
                  relatedPartyEntities[i].entity_control_person_is_us_citizen ===
                  "Yes"
                  ? "Yes"
                  : "No",
              entityControlPersonIsUSCitizenError: "",
              entityControlPersonPIIAvailable:
                relatedPartyEntities[i].entity_control_person_address_line_1 !==
                  ""
                  ? "Yes"
                  : "No",
              entityControlPersonPIIAvailableError: "",
              entityControlPersonAddressLine1:
                relatedPartyEntities[i].entity_control_person_address_line_1,
              entityControlPersonAddressLine1Error: "",
              entityControlPersonAddressLine2:
                relatedPartyEntities[i].entity_control_person_address_line_2,
              entityControlPersonAddressLine2Error: "",
              entityControlPersonCity:
                relatedPartyEntities[i].entity_control_person_city,
              entityControlPersonCityError: "",
              entityControlPersonState:
                relatedPartyEntities[i].entity_control_person_state,
              entityControlPersonStateError: "",
              entityControlPersonPostalCode:
                relatedPartyEntities[i].entity_control_person_postal_code,
              entityControlPersonPostalCodeError: "",
              entityControlPersonPhoneNumber:
                relatedPartyEntities[i].entity_control_person_phone_number,
              entityControlPersonPhoneNumberError: "",
              entityControlPersonTaxIDNumber:
                relatedPartyEntities[i].entity_control_person_tax_id_number,
              entityControlPersonTaxIDNumberError: "",
              entityControlPersonDOB:
                relatedPartyEntities[i].entity_control_person_dob,
              entityControlPersonDOBError: "",
              entityCertificateOfFormation: "",
              entityCertificateOfFormationError: "",
              entityProofOfAddress: "",
              entityProofOfAddressError: "",
              entityControlPersonPassportDocument: "",
              entityControlPersonPassportDocumentError: "",
              entityControlPersonProofOfAddress: "",
              entityControlPersonProofOfAddressError: "",
              entityIsTrustManagementCompany: "",
              entityIsTrustManagementCompanyError: "",
              entityHasAlternateSignatory: "",
              entityHasAlternateSignatoryError: "",
              entityAlternateSignatoryFullName: "",
              entityAlternateSignatoryFullNameError: "",
              entityAlternateSignatoryEmail: "",
              entityAlternateSignatoryEmailError: "",
              entityAlternateSignatoryTitle: "",
              entityAlternateSignatoryTitleError: "",
            };
            trusteeArray.push(entity);
          } else if (relatedPartyEntities[i].role === "BO") {
            let entity = {
              relatedPartyType: relatedPartyEntities[i].related_party_type,
              relatedPartyTypeError: "",
              entityPercentOwned: relatedPartyEntities[i].entity_percent_owned,
              entityPercentOwnedError: "",
              entityName: relatedPartyEntities[i].entity_name,
              entityNameError: "",
              entityAddressLine1: relatedPartyEntities[i].entity_address_line_1,
              entityAddressLine1Error: "",
              entityAddressLine2: relatedPartyEntities[i].entity_address_line_2,
              entityAddressLine2Error: "",
              entityCity: relatedPartyEntities[i].entity_city,
              entityCityError: "",
              entityState: relatedPartyEntities[i].entity_state,
              entityStateError: "",
              entityPostalCode: relatedPartyEntities[i].entity_postal_code,
              entityPostalCodeError: "",
              entityTaxIDNumber: relatedPartyEntities[i].entity_tax_id_number,
              entityTaxIDNumberError: "",
              entityPhoneNumber: relatedPartyEntities[i].entity_phone_number,
              entityPhoneNumberError: "",
              entityStateOfIncorporation:
                relatedPartyEntities[i].entity_state_of_incorporation,
              entityStateOfIncorporationError: "",
              entityMailingAddressDifferent:
                relatedPartyEntities[i].address_type === "R" ? "Yes" : "No",
              entityMailingAddressDifferentError: "",
              entityMailingAddressLine1:
                relatedPartyEntities[i].entity_mailing_address_line_1,
              entityMailingAddressLine1Error: "",
              entityMailingAddressLine2:
                relatedPartyEntities[i].entity_mailing_address_line_2,
              entityMailingAddressLine2Error: "",
              entityMailingCity: relatedPartyEntities[i].entity_mailing_city,
              entityMailingCityError: "",
              entityMailingState: relatedPartyEntities[i].entity_mailing_state,
              entityMailingStateError: "",
              entityMailingPostalCode:
                relatedPartyEntities[i].entity_mailing_postal_code,
              entityMailingPostalCodeError: "",
              entityType: relatedPartyEntities[i].entity_type,
              entityTypeError: "",
              entityControlPersonFirstName:
                relatedPartyEntities[i].entity_control_person_first_name,
              entityControlPersonFirstNameError: "",
              entityControlPersonLastName:
                relatedPartyEntities[i].entity_control_person_last_name,
              entityControlPersonLastNameError: "",
              entityControlPersonEmail:
                relatedPartyEntities[i].entity_control_person_email,
              entityControlPersonEmailError: "",
              entityControlPersonIsUSBased:
                relatedPartyEntities[i].entity_control_person_is_us_based ===
                  true ||
                  relatedPartyEntities[i].entity_control_person_is_us_based ===
                  "Yes"
                  ? "Yes"
                  : "No",
              entityControlPersonIsUSBasedError: "",
              entityControlPersonIsUSCitizen:
                relatedPartyEntities[i].entity_control_person_is_us_citizen ===
                  true ||
                  relatedPartyEntities[i].entity_control_person_is_us_citizen ===
                  "Yes"
                  ? "Yes"
                  : "No",
              entityControlPersonIsUSCitizenError: "",
              entityControlPersonPIIAvailable:
                relatedPartyEntities[i].entity_control_person_address_line_1 !==
                  ""
                  ? "Yes"
                  : "No",
              entityControlPersonPIIAvailableError: "",
              entityControlPersonAddressLine1:
                relatedPartyEntities[i].entity_control_person_address_line_1,
              entityControlPersonAddressLine1Error: "",
              entityControlPersonAddressLine2:
                relatedPartyEntities[i].entity_control_person_address_line_2,
              entityControlPersonAddressLine2Error: "",
              entityControlPersonCity:
                relatedPartyEntities[i].entity_control_person_city,
              entityControlPersonCityError: "",
              entityControlPersonState:
                relatedPartyEntities[i].entity_control_person_state,
              entityControlPersonStateError: "",
              entityControlPersonPostalCode:
                relatedPartyEntities[i].entity_control_person_postal_code,
              entityControlPersonPostalCodeError: "",
              entityControlPersonPhoneNumber:
                relatedPartyEntities[i].entity_control_person_phone_number,
              entityControlPersonPhoneNumberError: "",
              entityControlPersonTaxIDNumber:
                relatedPartyEntities[i].entity_control_person_tax_id_number,
              entityControlPersonTaxIDNumberError: "",
              entityControlPersonDOB:
                relatedPartyEntities[i].entity_control_person_dob,
              entityControlPersonDOBError: "",
              entityCertificateOfFormation: "",
              entityCertificateOfFormationError: "",
              entityProofOfAddress: "",
              entityProofOfAddressError: "",
              entityControlPersonPassportDocument: "",
              entityControlPersonPassportDocumentError: "",
              entityControlPersonProofOfAddress: "",
              entityControlPersonProofOfAddressError: "",
              entityIsTrustManagementCompany: "",
              entityIsTrustManagementCompanyError: "",
              entityHasAlternateSignatory: "",
              entityHasAlternateSignatoryError: "",
              entityAlternateSignatoryFullName: "",
              entityAlternateSignatoryFullNameError: "",
              entityAlternateSignatoryEmail: "",
              entityAlternateSignatoryEmailError: "",
              entityAlternateSignatoryTitle: "",
              entityAlternateSignatoryTitleError: "",
              entityControlPersonRole: "",
              entityControlPersonRoleError: "",
              entityNestedControlEntityTrusteeType:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_related_party_type
                  : "",
              entityNestedControlEntityTrusteeTypeError: "",
              entityNestedControlEntityName:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_name
                  : "",
              entityNestedControlEntityNameError: "",
              entityNestedControlEntityType:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_type
                  : "",
              entityNestedControlEntityTypeError: "",
              entityNestedControlEntityPercentOwned:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_percent_owned
                  : "",
              entityNestedControlEntityPercentOwnedError: "",
              entityNestedControlEntityStateOfIncorporation:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_state_of_incorporation
                  : "",
              entityNestedControlEntityStateOfIncorporationError: "",
              entityNestedControlEntityTaxIDNumber:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_tax_id_number
                  : "",
              entityNestedControlEntityTaxIDNumberError: "",
              entityNestedControlEntityPhoneNumber:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_phone_number
                  : "",
              entityNestedControlEntityPhoneNumberError: "",
              entityNestedControlEntityAddressLine1:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_address_line_1
                  : "",
              entityNestedControlEntityAddressLine1Error: "",
              entityNestedControlEntityAddressLine2:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_address_line_2
                  : "",
              entityNestedControlEntityAddressLine2Error: "",
              entityNestedControlEntityCity:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_city
                  : "",
              entityNestedControlEntityCityError: "",
              entityNestedControlEntityState:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_state
                  : "",
              entityNestedControlEntityStateError: "",
              entityNestedControlEntityPostalCode:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_postal_code
                  : "",
              entityNestedControlEntityPostalCodeError: "",
              entityNestedControlEntityIsTrustManagementCompany:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i]
                    .nested_entity_is_trust_management_company
                  : "",
              entityNestedControlEntityIsTrustManagementCompanyError: "",
              entityNestedControlEntityAddressType:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_address_type
                  : "",
              entityNestedControlEntityAddressTypeError: "",
              entityNestedControlEntityCertificateOfFormation: "",
              entityNestedControlEntityCertificateOfFormationError: "",
              entityNestedControlEntityProofOfAddress: "",
              entityNestedControlEntityProofOfAddressError: "",
              entityNestedControlEntityControlPersonFirstName:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i]
                    .nested_entity_control_person_first_name
                  : "",
              entityNestedControlEntityControlPersonFirstNameError: "",
              entityNestedControlEntityControlPersonLastName:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i]
                    .nested_entity_control_person_last_name
                  : "",
              entityNestedControlEntityControlPersonLastNameError: "",
              entityNestedControlEntityControlPersonEmail:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_control_person_email
                  : "",
              entityNestedControlEntityControlPersonEmailError: "",
              entityNestedControlEntityControlPersonIsUSBased:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i]
                    .nested_entity_control_person_is_us_based
                  : "",
              entityNestedControlEntityControlPersonIsUSBasedError: "",
              entityNestedControlEntityControlPersonIsUSCitizen:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i]
                    .nested_entity_control_person_is_us_citizen
                  : "",
              entityNestedControlEntityControlPersonIsUSCitizenError: "",
              entityNestedControlEntityControlPersonPhoneNumber:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i]
                    .nested_entity_control_person_phone_number
                  : "",
              entityNestedControlEntityControlPersonPhoneNumberError: "",
              entityNestedControlEntityControlPersonPIIAvailable:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i]
                    .nested_entity_control_person_address_line_1 !== ""
                    ? "Yes"
                    : "No"
                  : "",
              entityNestedControlEntityControlPersonPIIAvailableError: "",
              entityNestedControlEntityControlPersonAddressLine1:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i]
                    .nested_entity_control_person_address_line_1
                  : "",
              entityNestedControlEntityControlPersonAddressLine1Error: "",
              entityNestedControlEntityControlPersonAddressLine2:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i]
                    .nested_entity_control_person_address_line_2
                  : "",
              entityNestedControlEntityControlPersonAddressLine2Error: "",
              entityNestedControlEntityControlPersonCity:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_control_person_city
                  : "",
              entityNestedControlEntityControlPersonCityError: "",
              entityNestedControlEntityControlPersonState:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_control_person_state
                  : "",
              entityNestedControlEntityControlPersonStateError: "",
              entityNestedControlEntityControlPersonPostalCode:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i]
                    .nested_entity_control_person_postal_code
                  : "",
              entityNestedControlEntityControlPersonPostalCodeError: "",
              entityNestedControlEntityControlPersonTaxIDNumber:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i]
                    .nested_entity_control_person_tax_id_number
                  : "",
              entityNestedControlEntityControlPersonTaxIDNumberError: "",
              entityNestedControlEntityControlPersonDOB:
                relatedPartyEntities[i].nested_related_party_type !== null &&
                  relatedPartyEntities[i].nested_related_party_type !==
                  undefined &&
                  relatedPartyEntities[i].nested_related_party_type !== ""
                  ? relatedPartyEntities[i].nested_entity_control_person_dob
                  : "",
              entityNestedControlEntityControlPersonDOBError: "",
              entityNestedControlEntityControlPersonPassportDocument: "",
              entityNestedControlEntityControlPersonPassportDocumentError: "",
              entityNestedControlEntityControlPersonProofOfAddress: "",
              entityNestedControlEntityControlPersonProofOfAddressError: "",
              entityNestedControlEntityControlPersonAddressType: "",
              entityNestedControlEntityControlPersonAddressTypeError: "",
            };
            beneficialOwnerArray.push(entity);
          }
        }

        for (let i = 0; i < relatedPartyPeople.length; i++) {
          if (relatedPartyPeople[i].role === "TST") {
            let person = {
              relatedPartyType: relatedPartyPeople[i].related_party_type,
              relatedPartyTypeError: "",
              firstName: relatedPartyPeople[i].first_name,
              lastName: relatedPartyPeople[i].last_name,
              email: relatedPartyPeople[i].email,
              phoneNumber: relatedPartyPeople[i].phone_number,
              addressLine1: relatedPartyPeople[i].address_line_1,
              addressLine2: relatedPartyPeople[i].address_line_2,
              city: relatedPartyPeople[i].city,
              state: relatedPartyPeople[i].state,
              country: relatedPartyPeople[i].country,
              postalCode: relatedPartyPeople[i].postal_code,
              dateOfBirth: relatedPartyPeople[i].date_of_birth,
              dateOfBirthError: "",
              idNum: relatedPartyPeople[i].id_num,
              idNumError: "",
              firstNameError: "",
              lastNameError: "",
              emailError: "",
              phoneNumberError: "",
              addressLine1Error: "",
              addressLine2Error: "",
              cityError: "",
              stateError: "",
              countryError: "",
              postalCodeError: "",
              isUSCitizen:
                relatedPartyPeople[i].is_us_citizen === true ? "Yes" : "No",
              isUSCitizenError: "",
              citizenship: "United States",
              country: "United States",
              citizenshipError: "",
              countryError: "",
              percentOwnership: relatedPartyPeople[i].percent_ownership,
              percentOwnershipError: "",
            };
            trusteeArray.push(person);
          } else if (relatedPartyPeople[i].role === "BO") {
            let person = {
              relatedPartyType: relatedPartyPeople[i].related_party_type,
              relatedPartyTypeError: "",
              firstName: relatedPartyPeople[i].first_name,
              lastName: relatedPartyPeople[i].last_name,
              email: relatedPartyPeople[i].email,
              phoneNumber: relatedPartyPeople[i].phone_number,
              addressLine1: relatedPartyPeople[i].address_line_1,
              addressLine2: relatedPartyPeople[i].address_line_2,
              city: relatedPartyPeople[i].city,
              state: relatedPartyPeople[i].state,
              country: relatedPartyPeople[i].country,
              postalCode: relatedPartyPeople[i].postal_code,
              dateOfBirth: relatedPartyPeople[i].date_of_birth,
              dateOfBirthError: "",
              idNum: relatedPartyPeople[i].id_num,
              idNumError: "",
              firstNameError: "",
              lastNameError: "",
              emailError: "",
              phoneNumberError: "",
              addressLine1Error: "",
              addressLine2Error: "",
              cityError: "",
              stateError: "",
              countryError: "",
              postalCodeError: "",
              isUSCitizen:
                relatedPartyPeople[i].is_us_citizen === true ? "Yes" : "No",
              isUSCitizenError: "",
              citizenship: "United States",
              country: "United States",
              citizenshipError: "",
              countryError: "",
              percentOwnership: relatedPartyPeople[i].percent_ownership,
              percentOwnershipError: "",
            };
            beneficialOwnerArray.push(person);
          }
        }

        setTrusteeInformation(trusteeArray);
        setBeneficialOwnerInformation(beneficialOwnerArray);
      }
    }
  };

  useEffect(() => {
    fetchClientData();
  }, []);

  const entityOptions = [
    { value: "LT", label: "Revocable Trust" },
    { value: "TRUST", label: "Irrevocable Trust" },
    { value: "SM_LLC", label: "Single-member LLC" },
    { value: "LLC", label: "LLC" },
    { value: "GP", label: "General Partnership" },
    { value: "LP", label: "Limited Partnership" },
    { value: "C-CORP", label: "C-Corp" },
    { value: "S-CORP", label: "S-Corp" },
    { value: "TRAD_IRA", label: "Traditional IRA" },
    { value: "ROTH_IRA", label: "Roth IRA" },
    { value: "SEP_IRA", label: "SEP IRA" },
    { value: "SOLO_401K", label: "Solo 401K" },
    { value: "SELF_DIRECTED_401K", label: "Self Directed 401K" }
  ];


  return (
    <div className="container ps-3 pe-3">
      <div className="row g-0 w-100 mb-2">
        {accountType === "TRT" ? (
          <h5>Grantor Information</h5>
        ) : (
          <h5>General Information</h5>
        )}
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-3">
          <TextInputField
            value={firstName}
            setValue={setFirstName}
            setValueError={setFirstNameError}
            valueError={firstNameError}
            label={"First Name"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={firstName !== ""}
            id={"client-first-name-input"}
          />
        </div>
        <div className="col">
          <TextInputField
            value={lastName}
            setValue={setLastName}
            setValueError={setLastNameError}
            valueError={lastNameError}
            label={"Last Name"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={lastName !== ""}
            id={"client-last-name-input"}
          />
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-3">
          <TextInputField
            value={email}
            setValue={setEmail}
            setValueError={setEmailError}
            valueError={emailError}
            label={"Email"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={email !== ""}
            id={"client-email-input"}
          />
        </div>
        <div className="col">
          <TextInputField
            value={phoneNumber}
            setValue={setPhoneNumber}
            setValueError={setPhoneNumberError}
            valueError={phoneNumberError}
            label={"Phone Number"}
            required={true}
            fullWidth={true}
            size={"small"}
            disabled={phoneNumber !== ""}
            id={"client-phone-number-input"}
          />
          {/* <PhoneInputField
                  shrink={true}
                  label={"Phone Number"}
                  size={"small"}
                  fullWidth={true}
                  required={true}
                  value={phoneNumber}
                  setValue={setPhoneNumber}
                  valueError={phoneNumberError}
                  setValueError={setPhoneNumberError}
                  disabled={phoneNumber !== ""}
                /> */}
        </div>
      </div>
      <div className="row g-0 w-100 mb-4">
        <div className="col pe-3">
          <SelectInputField
            value={domicileCountry}
            setValue={setDomicileCountry}
            valueError={domicileCountryError}
            setValueError={setDomicileCountryError}
            label={"Domicile Country"}
            selectOptions={countryList}
            required={true}
            fullWidth={true}
            shrink={true}
            size={"small"}
            disabled={domicileCountry !== ""}
            id={"client-domicile-country-input"}
          />
        </div>
        <div className="col">
          <StyledDateInputField
            value={dateAdded}
            fullWidth={true}
            setValue={setDateAdded}
            valueError={dateAddedError}
            setValueError={setDateAddedError}
            size={"small"}
            label={"Date Added"}
            disabled={dateAdded !== ""}
          />
        </div>
      </div>
      {(user && user.isAuthenticated && user.userRole !== 'Vendor') && <>
        <div className="row g-0 w-100 mb-2">
          {accountType === "TRT" ? (
            <h5>Grantor Mailing Address</h5>
          ) : (
            <h5>Mailing Address</h5>
          )}
        </div>
        <div className="row g-0 w-100 mb-4">
          <div className="col pe-3">
            <TextInputField
              value={addressLine1}
              setValue={setAddressLine1}
              setValueError={setAddressLine1Error}
              valueError={addressLine1Error}
              label={"Address Line 1"}
              required={true}
              fullWidth={true}
              shrink={true}
              size={"small"}
              disabled={addressLine1 !== ""}
              id={"client-address-line-1-input"}
            />
          </div>
          <div className="col">
            <TextInputField
              value={addressLine2}
              setValue={setAddressLine2}
              setValueError={setAddressLine2Error}
              valueError={addressLine2Error}
              label={"Address Line 2"}
              required={true}
              fullWidth={true}
              size={"small"}
              shrink={true}
              disabled={true}
              id={"client-address-line-2-input"}
            />
          </div>
        </div>
        <div className="row g-0 w-100 mb-4">
          <div className="col pe-3">
            <TextInputField
              value={city}
              setValue={setCity}
              setValueError={setCityError}
              valueError={cityError}
              label={"City"}
              required={true}
              fullWidth={true}
              size={"small"}
              disabled={city !== ""}
              id={"client-city-input"}
            />
          </div>
          <div className="col">
            <SelectInputField
              value={state}
              setValue={setState}
              valueError={stateError}
              setValueError={setStateError}
              label={"State"}
              selectOptions={stateList}
              required={true}
              fullWidth={true}
              shrink={true}
              size={"small"}
              disabled={state !== ""}
              id={"client-state-input"}
            />
          </div>
        </div>
        <div className="row g-0 w-100 mb-4">
          <div className="col pe-3">
            <SelectInputField
              value={country}
              setValue={setCountry}
              valueError={countryError}
              setValueError={setCountryError}
              label={"Country"}
              selectOptions={countryList}
              required={true}
              fullWidth={true}
              shrink={true}
              size={"small"}
              disabled={country !== ""}
              id={"client-country-input"}
            />
          </div>
          <div className="col">
            <TextInputField
              value={postalCode}
              setValue={setPostalCode}
              setValueError={setPostalCodeError}
              valueError={postalCodeError}
              label={"Postal Code"}
              required={true}
              fullWidth={true}
              size={"small"}
              disabled={postalCode !== ""}
              id={"client-postal-code-input"}
            />
          </div>
        </div>
        <div className="row g-0 w-100 mb-2">
          {accountType === "TRT" ? (
            <h5>Grantor Personal Information</h5>
          ) : (
            <h5>Personal Information</h5>
          )}
        </div>
        <div className="row g-0 w-100 mb-4">
          <div className="col pe-3">
            <StyledDateInputField
              value={dob}
              fullWidth={true}
              setValue={setDob}
              valueError={dobError}
              setValueError={setDobError}
              size={"small"}
              label={"Date of Birth"}
              disabled={dob !== ""}
            />
          </div>
          <div className="col">
            {domicileCountry === "United States" && (
              <TextInputField
                value={idNum}
                setValue={setIdNum}
                valueError={idNumError}
                setValueError={setIdNumError}
                label={"SSN"}
                required={true}
                fullWidth={true}
                size={"small"}
                mask={{
                  maskFormatter: formatSSN,
                  maskPattern: "999-99-9999",
                }}
                disabled={idNum !== ""}
                id={"client-ssn-input"}
              />
            )}
            {domicileCountry !== "United States" && (
              <TextInputField
                value={idNum}
                setValue={setIdNum}
                valueError={idNumError}
                setValueError={setIdNumError}
                label={"Passport Number"}
                required={true}
                fullWidth={true}
                size={"small"}
                disabled={idNum !== ""}
                id={"client-passport-input"}
              />
            )}
          </div>
        </div> </>}
      {accountType !== "IND" && (
        <React.Fragment>
          <div className="row g-0 w-100 mb-3">
            <h5>Entity Information</h5>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-3">
              <TextInputField
                value={entityLegalName}
                setValue={setEntityLegalName}
                setValueError={setEntityLegalNameError}
                valueError={entityLegalNameError}
                label={"Entity Name"}
                required={true}
                fullWidth={true}
                size={"small"}
                disabled={true}
                shrink={true}
                id={"entity-name-input"}
              />
            </div>
            <div className="col">
              <SelectInputField
                value={entityType}
                setValue={setEntityType}
                valueError={entityTypeError}
                setValueError={setEntityTypeError}
                selectOptions={entityOptions}
                required={true}
                fullWidth={true}
                size={"small"}
                disabled={true}
                id={"entity-type-select"}
                label={"Entity Type"}
              />
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-3">
              <SelectInputField
                value={entityStateOfIncorporation}
                setValue={setEntityStateOfIncorporation}
                valueError={entityStateOfIncorporationError}
                setValueError={setEntityStateOfIncorporationError}
                selectOptions={stateList}
                required={true}
                fullWidth={true}
                size={"small"}
                disabled={true}
                shrink={true}
                id={"entity-state-of-incorporation-select"}
                label={"State of Incorporation"}
              />
            </div>
            <div className="col">
              <PhoneInputField
                shrink={true}
                label={"Entity Phone Number"}
                size={"small"}
                fullWidth={true}
                required={true}
                value={entityPhoneNumber}
                setValue={setEntityPhoneNumber}
                valueError={entityPhoneNumberError}
                setValueError={setEntityPhoneNumberError}
                disabled={true}
                id={"entity-phone-number-input"}
              />
            </div>
          </div>
          {entityType !== "" &&
          (user && user.isAuthenticated && user.userRole !== 'Vendor') && 
            entityType !== "LT" &&
            entityType !== "SM_LLC" && (
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-0">
                  <TextInputField
                    value={entityTaxIDNumber}
                    setValue={setEntityTaxIDNumber}
                    valueError={entityTaxIDNumberError}
                    setValueError={setEntityTaxIDNumberError}
                    label={"Entity EIN"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={true}
                    id={"entity-tax-id-number-input"}
                  />
                </div>
              </div>
            )}
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-0">
              <h6>Entity Address Information</h6>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-3">
              <TextInputField
                value={entityAddressLine1}
                setValue={setEntityAddressLine1}
                setValueError={setEntityAddressLine1Error}
                valueError={entityAddressLine1Error}
                label={"Address Line 1"}
                required={true}
                fullWidth={true}
                size={"small"}
                disabled={true}
                shrink={true}
                id={"entity-address-line-1-input"}
              />
            </div>
            <div className="col">
              <TextInputField
                value={entityAddressLine2}
                setValue={setEntityAddressLine2}
                setValueError={setEntityAddressLine2Error}
                valueError={entityAddressLine2Error}
                label={"Address Line 2"}
                required={true}
                fullWidth={true}
                size={"small"}
                disabled={true}
                shrink={true}
                id={"entity-address-line-2-input"}
              />
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-3">
              <TextInputField
                value={entityCity}
                setValue={setEntityCity}
                setValueError={setEntityCityError}
                valueError={entityCityError}
                label={"City"}
                required={true}
                fullWidth={true}
                size={"small"}
                disabled={true}
                shrink={true}
                id={"entity-city-input"}
              />
            </div>
            <div className="col">
              <SelectInputField
                value={entityState}
                setValue={setEntityState}
                valueError={entityStateError}
                setValueError={setEntityStateError}
                selectOptions={stateList}
                required={true}
                fullWidth={true}
                size={"small"}
                disabled={true}
                shrink={true}
                id={"entity-state-select"}
                label={"State"}
              />
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col pe-0">
              <TextInputField
                value={entityPostalCode}
                setValue={setEntityPostalCode}
                setValueError={setEntityPostalCodeError}
                valueError={entityPostalCodeError}
                label={"Postal Code"}
                required={true}
                fullWidth={true}
                size={"small"}
                disabled={true}
                shrink={true}
                id={"entity-postal-code-input"}
                mask={{
                  maskPattern: "999999999999",
                  maskFormatter: (e) => e,
                }}
              />
            </div>
          </div>
          {entityMailingAddressLine1 !== "" && (
            <React.Fragment>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-0">
                  <h6>Entity Mailing Address Information</h6>
                </div>
              </div>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-3">
                  <TextInputField
                    value={entityMailingAddressLine1}
                    setValue={setEntityMailingAddressLine1}
                    setValueError={setEntityMailingAddressLine1Error}
                    valueError={entityMailingAddressLine1Error}
                    label={"Address Line 1"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={true}
                    shrink={true}
                    id={"entity-address-line-1-input"}
                  />
                </div>
                <div className="col">
                  <TextInputField
                    value={entityMailingAddressLine2}
                    setValue={setEntityMailingAddressLine2}
                    setValueError={setEntityMailingAddressLine2Error}
                    valueError={entityMailingAddressLine2Error}
                    label={"Address Line 2"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={true}
                    shrink={true}
                    id={"entity-address-line-2-input"}
                  />
                </div>
              </div>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-3">
                  <TextInputField
                    value={entityMailingCity}
                    setValue={setEntityMailingCity}
                    setValueError={setEntityMailingCityError}
                    valueError={entityMailingCityError}
                    label={"City"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={true}
                    shrink={true}
                    id={"entity-city-input"}
                  />
                </div>
                <div className="col">
                  <SelectInputField
                    value={entityMailingState}
                    setValue={setEntityMailingState}
                    valueError={entityMailingStateError}
                    setValueError={setEntityMailingStateError}
                    selectOptions={stateList}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={true}
                    shrink={true}
                    id={"entity-state-select"}
                    label={"State"}
                  />
                </div>
              </div>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-0">
                  <TextInputField
                    value={entityMailingPostalCode}
                    setValue={setEntityMailingPostalCode}
                    setValueError={setEntityMailingPostalCodeError}
                    valueError={entityMailingPostalCodeError}
                    label={"Postal Code"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={true}
                    shrink={true}
                    id={"entity-postal-code-input"}
                    mask={{
                      maskPattern: "999999999999",
                      maskFormatter: (e) => e,
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          )}

          {authorizedSignatoryName !== "" && (
            <React.Fragment>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-0">
                  <h6>Authorized Signatory Information</h6>
                </div>
              </div>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-3">
                  <TextInputField
                    value={authorizedSignatoryName}
                    setValue={setAuthorizedSignatoryName}
                    setValueError={setAuthorizedSignatoryNameError}
                    valueError={authorizedSignatoryNameError}
                    label={"Signatory Name"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={true}
                    shrink={true}
                    id={"entity-authorized-signatory-name-input"}
                  />
                </div>
                <div className="col pe-0">
                  <TextInputField
                    value={authorizedSignatoryEmail}
                    setValue={setAuthorizedSignatoryEmail}
                    setValueError={setAuthorizedSignatoryEmailError}
                    valueError={authorizedSignatoryEmailError}
                    label={"Signatory Email"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={true}
                    shrink={true}
                    id={"entity-authorized-signatory-email-input"}
                  />
                </div>
              </div>
              <div className="row g-0 w-100 mb-3">
                <div className="col pe-0">
                  <TextInputField
                    value={authorizedSignatoryTitle}
                    setValue={setAuthorizedSignatoryTitle}
                    setValueError={setAuthorizedSignatoryTitleError}
                    valueError={authorizedSignatoryTitleError}
                    label={"Signatory Title"}
                    required={true}
                    fullWidth={true}
                    size={"small"}
                    disabled={true}
                    shrink={true}
                    id={"entity-authorized-signatory-title-input"}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
          {trusteeInformation !== null &&
            trusteeInformation !== undefined &&
            trusteeInformation.length !== 0 && (
              <React.Fragment>
                <div className="row g-0 w-100 mt-5">
                  <h6>Trustee Information</h6>
                </div>
                <div className="row g-0 w-100">
                  <InfiniteTabInput
                    tabs={trusteeInformation.map(
                      (elem, idx) => "Trustee #" + (idx + 1).toString()
                    )}
                    activeTab={trusteeActiveTab}
                    setActiveTab={setTrusteeActiveTab}
                  />
                  <InfiniteInputComponent
                    inputFields={trusteeInformation}
                    setInputFields={setTrusteeInformation}
                    index={trusteeActiveTab.match(/\d+/) - 1}
                    handleRemoveFields={() => console.log("")}
                    disabled={true}
                    setActiveTab={setTrusteeActiveTab}
                    setHideButton={() => console.log("")}
                    hideAlterButtons={true}
                    accountType={entityType}
                    relatedPartyType={"TRT"}
                  />
                </div>
              </React.Fragment>
            )}
          {beneficialOwnerInformation !== null &&
            beneficialOwnerInformation !== undefined &&
            beneficialOwnerInformation.length !== 0 && (
              <React.Fragment>
                <div className="row g-0 w-100 mt-5">
                  <h6>Beneficial Owner Information</h6>
                </div>
                <div className="row g-0 w-100">
                  <InfiniteTabInput
                    tabs={beneficialOwnerInformation.map(
                      (elem, idx) => "Beneficial Owner #" + (idx + 1).toString()
                    )}
                    activeTab={beneficialOwnerActiveTab}
                    setActiveTab={setBeneficialOwnerActiveTab}
                  />
                  <InfiniteInputComponent
                    inputFields={beneficialOwnerInformation}
                    setInputFields={setBeneficialOwnerInformation}
                    index={beneficialOwnerActiveTab.match(/\d+/) - 1}
                    handleRemoveFields={() => console.log("")}
                    disabled={true}
                    setActiveTab={setBeneficialOwnerActiveTab}
                    setHideButton={() => console.log("")}
                    hideAlterButtons={true}
                    accountType={entityType}
                    relatedPartyType={"BO"}
                  />
                </div>
              </React.Fragment>
            )}
        </React.Fragment>
      )}
      <div className="row g-0 w-100 mb-3"></div>
      {/* <div className="row g-0 w-100 mb-2">
        <div className="d-flex align-items-center">
          <h6 className="mt-auto mb-auto">Authorized To:</h6>
          <span className="ms-2 body-text-md-medium">
            Recommend Investments
          </span>
        </div>
      </div> */}
    </div>
  );
};
export default GeneralInfo;
