import * as dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import ChooseClientTable from "../../../../../Components/ChooseClientTable/ChooseClientTable";
import Graph from "./Graph/Graph";
import "./Styles/Styles.scss";
import InvestmentTable from "./InvestmentTable/InvestmentTable";

const Investments = ({ data, graph, setCapitalCallsSelected, setInvestment, setInvestmentsSelected }) => {
  const [investments, setInvestments] = useState([]);
  const [distributions, setDistributions] = useState([]);
  const [fundInvestments, setFundInvestments] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (data !== null && data !== undefined && data.length !== 0) {
      setFundInvestments(
        data.map((e) => ({
          fundName: e.fund,
          investmentAmount: e.investment_amount,
          totalDistributed: e.distributions,
          opportunityStatus: e.fund_status,
          investmentStage: e.status,
          accountName: e.account_name,
          investmentId: e.id,
        }))
      );
    }
  }, []);

  function moneyFormatter(cell) {
    if (cell === undefined || cell === null) {
      return "$ 0";
    }
    var integer = parseInt(cell.toString(), 10);
    return "$ " + integer.toLocaleString("en-US");
  }

  function fundStrategyFormatter(cell) {
    let stageMap = {
      STRUCTURED_CREDIT: "Private Credit",
      CLO_MANAGEMENT: "CLO Management",
      DIRECT_ACCESS: "Direct Access",
      PRIVATE_EQUITY: "Private Equity",
      CREDIT: "Credit",
      BUYOUT: "Buyout",
      GROWTH: "Growth",
      PORTCO: "PortCo"
    };
    return stageMap[cell];
  }

  function investmentStatusFormatter(cell) {
    if (cell !== undefined && cell !== null) {
      let investmentStatusMap = {
        P: "Pending Approval",
        PD: "Pending Sub Docs",
        PF: "Pending Funding",
        F: "Funded",
        A: "Approved",
        R: "Rejected",
      };
      return investmentStatusMap[cell.toString()];
    }
    return "";
  }

  const columns = () => [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "fundName",
      text: "Opportunity Name",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "investmentAmount",
      text: "Investment Amount",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
      formatter: moneyFormatter,
    },
    {
      dataField: "totalDistributed",
      text: "Total Distributed",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
      formatter: moneyFormatter,
    },
    {
      dataField: "opportunityStatus",
      text: "Opportunity Status",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "investmentStage",
      text: "Investment Stage",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
      formatter: investmentStatusFormatter,
    },
    {
      dataField: "accountName",
      text: "Account",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "capitalCalls",
      text: "Capital Call",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    // {
    //   dataField: "dummy",
    //   text: "Download",
    //   classes: "body-text-md-regular align-middle",
    //   headerClasses: "start-padding body-text-md-bold",
    //   formatter: (cellContent, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span className="body-text-md-medium">Available</span>
    //         <img
    //           src="https://d1swyfveypj49y.cloudfront.net/arrow-down-circle.svg"
    //           className="ms-2"
    //           onClick={() => console.log("Clicked")}
    //           style={{ height: "25px", width: "25px" }}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  const expandRow = {
    parentClassName: (isExpanded, row, rowIndex) => {
      if (isExpanded) return "table-row-style-focus";
      return "table-row-style";
    },
    onExpand: (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        // setExpanded([row.id]);
        // expanded: [...expanded, row.id];
        //setFundName(row.fundName);
      } else if (!isExpand) {
        // this.setState(() => ({
        //   expanded: [],
        // }));
        //setExpanded([]);
        //setFundName("All");
      }
    },
    onlyOneExpanding: true,
    renderer: (row, isExpanded, expanded) => (
      <div className="container-fluid w-90">
        <div className="row g-0 w-100 mt-4 mb-4 d-flex justify-content-center">
          <div className="row g-0 w-100 mb-3">
            <div className="col-12 table-row-secondary-information-container">
              <div className="row g-0 w-100">
                <div className="col-6 table-row-secondary-information-divider">
                  <div className="row g-0 w-100 mt-2 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Firm Name
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        {row.firmName}
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Fund Close Date
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        {row.fundClosingDate === "N/A"
                          ? "N/A"
                          : dayjs
                              .unix(row.fundClosingDate)
                              .format("MMM D, YYYY")}
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Target Fund Length
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        10 years
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Fund Strategy
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        {fundStrategyFormatter(row.strategy)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row g-0 w-100 mt-2 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Investment Date
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        {dayjs.unix(row.investmentDate).format("MMM D, YYYY")}
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Last Distribution Date
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        {row.lastDistributionDate === "N/A" ||
                        row.lastDistributionDate === undefined
                          ? "N/A"
                          : dayjs
                              .unix(row.lastDistributionDate)
                              .format("MMM D, YYYY")}
                      </span>
                    </div>
                  </div>
                  <div className="row g-0 w-100 mb-3">
                    <div className="col-6 ps-4">
                      <span className="table-row-secondary-information-section-title text-wrap">
                        Last Distribution Amount
                      </span>
                    </div>
                    <div className="col-6 ps-2">
                      <span className="table-row-secondary-information-section-value text-wrap">
                        {"$ " +
                          row.lastDistributionAmount.toLocaleString("en-US")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 w-100 mb-3">
            <div className="col-xxl-6 col-lg-8">
              <div className="row g-0 w-100 mt-4 align-items-center">
                <div className="d-flex">
                  <MuiButton
                    buttonText={"Open Fund Details Page"}
                    onClick={() => navigate("/fund/" + row.link)}
                    size={"large"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
  };

  return (
    <div className="container ps-3 pe-3">
      <div className="row g-0 w-100 mb-2">
        <h5>Investments</h5>
      </div>
      <Graph
        graph={graph}
        investments={investments}
        distributions={distributions}
        fromDate={null}
        toDate={null}
        accountCreationDate={"2022-10-21T17:20:30Z"}
        fundName={"All"}
      />
      {/* <div className="row g-0 w-100 mb-3">
        <ChooseClientTable
          data={fundInvestments}
          columns={columns}
          expandRow={() => {}}
        />
      </div> */}
      <div className="row g-0 w-100 mb-2">
        <InvestmentTable rowData={fundInvestments} setCapitalCallsSelected={setCapitalCallsSelected} setInvestment={setInvestment} setInvestmentsSelected={setInvestmentsSelected} />
      </div>
    </div>
  );
};
export default Investments;
